
<template>
  <el-row type="flex" justify="center" id="content-wrapper" :class="embed?'embed':''">
    <el-col :md="20" :lg="16" :xl="10" id="content">
    <h2>{{ $t('title.appselection') }}: {{ currentSelectionName }}</h2>

    <el-row v-loading="loading">
      <el-tabs :type="embed?'':'border-card'" v-if="currentSelection">
        <el-tab-pane :label="$t('g.section_general')">
          <label class="form-item">{{ $t("g.icon_url") }}</label>
          <DropZone
            :value="currentSelection.icon_url"
            :action="`${$config.baseUrl}/upload/`"
            :data="uploadIconData"
            dropmsg="Charger une icône"
            @change="updateField('icon_url', $event)"
          />

          <el-row>
            <el-col :span="8">
              <el-card :body-style="{ padding: '0px' }" v-if="currentSelection.icon_url">
                <img :src="currentSelection.icon_url | asset(256, 256)" class="image">
              </el-card>
            </el-col>
          </el-row>

          <div v-for="field in selection_default_translated_fields" v-bind:key="field.id">
              <label class="el-form-item__label">{{ tr(field.displayed_name) }}</label>
              <TranslatedField
              v-if="currentSelection.translated_fields"
              v-model="currentSelection.translated_fields[field.field_name]"
              :fieldType="field.type"
              @change="updateTranslatedField(field.field_name, $event)"
              />
          </div>

          <div v-for="field in selection_default_content_fields" v-bind:key="field.id">
              <label class="form-item">{{ tr(field.displayed_name) }}</label>
              <ContentField
              v-model="currentSelection.content_fields[field.field_name]"
              :fieldDef="field"
              @change="updateContentField(field.field_name, $event)"
              />
          </div>
        </el-tab-pane>

        <el-tab-pane :label="$t('g.section_pois')">

          <label class="form-item">Ajouter un POI</label>
          <el-row>
            <el-select
              v-model="searchPoiPattern"
              multiple
              filterable
              remote
              reserve-keyword
              placeholder="Titre du POI..."
              :remote-method="searchPoi"
              :loading="searchPoiLoading"
              ref="search"
              class="searchSelect"
              @change="searchPoiChanged">
              <el-option
                v-for="item in searchPoiResult"
                :key="item.id"
                :value="item.id">
                <span>
                  <img v-if="item.icon_url" :src="item.icon_url | asset(64, 64)" class="icon-image">
                  {{ tf(item, 'title') }}
                </span>
              </el-option>
            </el-select>

            <label class="form-item">Liste des pois dans la sélection</label>
            <el-tree
              :data="pois"
              draggable
              node-key="id"
              class="tree"
              @node-drop="handleTreeDrop"
              :allow-drop="allowTreeDrop"
              default-expand-all>
              <span class="custom-tree-node" slot-scope="{ node, data }">
                  <img v-if="data.icon_url" :src="data.icon_url | asset(64, 64)" class="icon-image">
                  <span class="tree-text">{{data.id}} - {{ tf(data, 'title') }}</span>
                  <el-button class="tree-delete" type="danger" icon="el-icon-delete" circle @click="() => deletePoi(data)"></el-button>
              </span>
            </el-tree>

          </el-row>

        </el-tab-pane>

        <el-tab-pane :label="$t('g.section_permissions')">
          <el-row>
            <label class="form-item">{{ $t("selection.public") }}</label>
            <el-checkbox
              :value="currentSelection.is_public"
              :disabled="!allowPermissions"
              @change="updateField('is_public', $event)"/>
          </el-row>

          <el-row>
            <label class="form-item">{{ $t("selection.typology") }}</label>
            <el-select
              v-model="currentSelection.typology"
              @change="updateField('typology', $event)">
              <el-option value="album" label="Album"/>
              <el-option value="parcours" label="Parcours"/>
              <el-option value="temporary" label="Temporaire"/>
            </el-select>
          </el-row>
        </el-tab-pane>
        <el-tab-pane :label="$t('g.section_admin')">
          <el-row>
            <el-col :span="8">
              <el-button
                type="danger"
                @click="deleteSelection()">Supprimer la sélection</el-button>
            </el-col>
          </el-row>
        </el-tab-pane>

      </el-tabs>
    </el-row>

  </el-col>
  </el-row>
</template>

<script>
import Vue from 'vue'
import TranslatedField from './TranslatedField'
import ContentField from './ContentField'
import DropZone from './DropZone'

export default {
  name: 'AppSelection',
  components: { TranslatedField, ContentField, DropZone },
  props: {
    embed: {
      type: Boolean,
      default: false
    },
    appID: {
      type: Number
    },
    selectionID: {
      type: Number
    }
  },

  data () {
    return {
      loading: true,
      currentSelection: null,
      selection_default_translated_fields: [],
      selection_default_content_fields: [],
      addPoiID: null,
      searchPoiPattern: [],
      searchPoiLoading: false,
      searchPoiResult: [],
      poisCache: {},
      pois: []
    }
  },

  methods: {
    searchPoiChanged (value) {
      if (value.length) {
        this.addPoiID = value[0]
        this.searchPoiPattern = []
        this.addPoiByID()
      }
    },
    handleTreeDrop (draggingNode, dropNode, dropType, ev) {
      let poilist = []
      for (let poi of this.pois) {
        if (poi.id === draggingNode.key) {
          continue;
        }
        poilist.push(poi.id)
      }

      const idx = poilist.indexOf(dropNode.key);
      if (dropType === 'before') {
        poilist.splice(idx, 0, draggingNode.key);
      } else {
        poilist.splice(idx + 1, 0, draggingNode.key);
      }

      this.currentSelection.poi_list_sorted = poilist
      this.updateField('poi_list', poilist)
      this.refreshPoiList()
    },
    allowTreeDrop(draggingNode, dropNode, type) {
      if (draggingNode.level === dropNode.level) {
        if (draggingNode.parent.id === dropNode.parent.id) {
          // Drag up || Drag down
          return type === "prev" || type === "next";
        }
      } else {
        // Process at different levels
        return false;
      }
    },
    async searchPoi (query) {
      if (query === '') {
        this.searchPoiResult = []
        return
      }

      this.searchPoiLoading = true
      await this.$store.dispatch('searchPoi', {
        search: query
      }).then((response) => {
        this.searchPoiResult = response.results
        this.searchPoiLoading = false
      })
    },

    async commit (field, value) {
      await this.$store.dispatch('updateSelection', {
        selectionID: this.currentSelection.id,
        [field]: value
      })
    },

    async updateField (field, value) {
      Vue.set(this.currentSelection, field, value)
      this.commit(field, value)
    },

    updateContentField (field, value) {
      let payload = this.currentSelection.content_fields
      payload[field] = value
      Vue.set(this.currentSelection, 'content_fields', payload)
      this.commit('content_fields', payload)
    },

    updateTranslatedField (field, value, lang) {
      let payload = this.currentSelection.translated_fields
      if (typeof payload === 'undefined') {
        payload = {}
      }

      if (lang === undefined) {
        payload[field] = value
      } else {
        let payloadField = payload[field]
        if (typeof payloadField === 'undefined') {
          payload[field] = {
            fr: '',
            en: ''
          }
          payloadField = payload[field]
        }
        payloadField[lang] = value
      }

      Vue.set(this.currentSelection, 'translated_fields', payload)
      this.commit('translated_fields', payload)
    },

    async addPoiByID () {
      let poilist = []
      let poiid = parseInt(this.addPoiID)
      for (let poi of this.pois) {
        if (poi.id === poiid) {
          this.addPoiID = null
          return
        }
        poilist.push(poi.id)
      }
      poilist.push(poiid)
      this.addPoiID = null
      this.currentSelection.poi_list_sorted = poilist
      this.updateField('poi_list', poilist)
      this.refreshPoiList()
    },

    deletePoi (data) {
      let poilist = []
      for (let poi of this.pois) {
        if (poi.id === data.id) continue
        poilist.push(poi.id)
      }
      this.currentSelection.poi_list_sorted = poilist
      this.updateField('poi_list', poilist)
      this.refreshPoiList()
    },

    updateProfileField (field, value) {
      let payload = this.currentSelection.translated_fields
      payload[field] = value
      Vue.set(this.currentSelection, 'translated_fields', payload)
      this.commit('translated_fields', payload)
    },

    deleteSelection () {
      let payload = {
        selectionID: this.$route.params.selectionID
      }
      this.$confirm(
        'Vous allez supprimer cette sélection de manière définitive. Continuez?',
        'Attention !', {
          confirmButtonText: 'Oui, je la supprime!',
          cancelButtonText: 'Annuler',
          type: 'warning'
        }).then(() => {
          this.$store.dispatch('deleteSelection', payload)
          this.$router.replace({name: 'appselections', params: this.$route.params})
          this.$message({
            message: 'La sélection a été supprimée',
            type: 'success'
          })
        })
    },

    async refreshPoiList () {
      this.pois = []
      for (let poiID of this.currentSelection.poi_list_sorted) {
        if (this.poisCache[poiID] !== undefined) {
            this.pois.push(this.poisCache[poiID])
        } else {
          await this.$store.dispatch('loadAppPoi', {poiID: poiID})
            .then((poi) => {
              this.poisCache[poi.id] = poi
              this.pois.push(poi)
            })
        }
      }
    },

    async prepare () {
      this.loading = true
      await this.$store.dispatch('ensureApp', {
        appID: this.appID
      })
      let currentSelection = await this.$store.dispatch('loadSelection', {
        appID: this.appID,
        selectionID: this.selectionID
      })
      this.selection_default_content_fields = this.$store.state.app.fields.selection_default_content_fields
      this.selection_default_translated_fields = this.$store.state.app.fields.selection_default_translated_fields
      for (let field of this.selection_default_translated_fields) {
        if (currentSelection.translated_fields[field.field_name] === undefined) {
          currentSelection.translated_fields[field.field_name] = {'fr': '', 'en': ''}
        }
      }
      this.currentSelection = currentSelection
      this.refreshPoiList()
      this.loading = false
    }

  },

  computed: {
    uploadIconData () {
      return {
        application_id: this.$route.params.appID,
        intent: 'app-media'
      }
    },
    currentAppState () {
      return this.$store.state.app
    },
    currentSelectionName () {
      try {
        return this.currentSelection.translated_fields.name.fr
      } catch (e) {
        return '...'
      }
    },
    allowPermissions () {
      if (this.currentSelection.id === this.$store.state.user.user_id) return false
      if (!this.$store.state.user.is_staff) return true
      return true
    }
  },

  watch: {
    '$route' (from, to) {
      this.prepare()
    }
  },

  mounted () {
    this.prepare()
  }
}
</script>

<style scoped>

.searchSelect {
  width: 100%;
}

.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    font-size: 14px;
    padding-right: 8px;
}

.card {
    width: 64px;
}
.icon-image {
    width: 28px;
    max-height: 28px;
    vertical-align: middle;
    margin-left: 10px;
}
.tree-text {
    margin-left: 10px;
    flex-grow: inherit;
}
.alert-description {
    font-size: 14px;
    padding: 5px 0;
}
</style>

<style>
.tree {
  border-top: 1px solid #ebeef5;
  border: 1px solid #ebebeb;
}
.tree .el-tree-node__content {
  border-bottom: 1px solid #ebeef5;
  min-height: 48px;
  line-height: 48px;
}
.tree .el-tree-node__expand-icon.is-leaf {
  display: none !important;
}
.el-dialog__body {
    padding-top: 0px;
    padding-bottom: 0px;
}
</style>
