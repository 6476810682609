<template>
  <el-row v-loading="loading">
    <h2>
      {{ $t('title.apppois') }}
      <el-button size="mini" icon="el-icon-plus" circle class="addpoi" @click="createPoi()"></el-button>
    </h2>

    <el-table :data="pois" width="100%">
      <el-table-column prop="id" width="64">
        <template slot-scope="scope">
          <router-link class="myClass" :to="$route.path + `${scope.row.id}`">
            <el-button type="primary" icon="el-icon-edit" circle/>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column prop="translated_fields.title" :label="$t('g.title')" sortable :formatter="trf"></el-table-column>
      <el-table-column prop="state" :label="$t('g.status')" width="128" sortable/>
    </el-table>
  </el-row>
</template>

<script>

export default {
  name: 'AppPois',
  props: ['appID', 'mapID'],

  data () {
    return {
      loading: false,
      pois: []
    }
  },

  methods: {
    createPoi () {
      this.$prompt('Titre du POI', 'Ajouter un POI', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        inputPattern: /^[\w]+$/,
        inputErrorMessage: 'Nom de POI invalide'
      }).then(value => {
        let payload = {
          appID: this.$route.params.appID,
          mapID: this.$route.params.mapID,
          payload: {
            translated_fields: {
              'title': {
                'fr': value.value
              }
            }
          }
        }
        this.$store.dispatch('createPoi', payload).then(poi => {
          this.$router.push({path: this.$route.path + `${poi.id}`})
        }).catch(error => {
          this.$notify({
            title: 'Echec création',
            message: `Erreur de création du Poi (${error})`,
            type: 'error'
          })
        })
      }).catch(function () {})
    }
  },

  async created () {
    await this.$store.dispatch('ensureApp', {
      appID: this.appID
    })
    await this.$store.dispatch('ensureMap', {
      appID: this.appID,
      mapID: this.mapID
    })
    await this.$store.dispatch('loadPois', {
      appID: this.appID,
      mapID: this.mapID
    })
    this.pois = this.$store.state.pois
    this.loading = false
  }
}
</script>

<style scoped>
.addpoi {
  float: right;
}
</style>
