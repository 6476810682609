<template>
  <el-row v-loading="loading">
    <el-col>
      <el-row>
        <el-col :md="16">
          <h2>{{ $t('title.appmaps') }}</h2>
        </el-col>
        <el-col class="right" :md="8">
          <el-input
            placeholder="Filtrer"
            suffix-icon="el-icon-search"
            v-model="filterText"
            ref="filter"
            @keyup.native="filterTable" v-if="maps"/>
          <el-button size="mini" icon="el-icon-plus" circle class="addmap" @click="createMap()"></el-button>
        </el-col>
      </el-row>

      <div id="mapcards">
        <div
          class="mapcard"
          v-for="map in mapsFiltered"
          :key="map.id">
          <el-card
              :body-style="{ padding: '0px' }">
            <template v-if="map.is_indoor && map.bounds && map.bounds.max_lon == 4096 && map.bounds.max_lat == 2048">
              <router-link :to="`/public/apps/${$store.state.app.id}/maps/${map.id}/360?mode=embed`" style="position: absolute; z-index: 1000; right: 10px; top: 10px;">
                <el-button icon="el-icon-view" circle size="mini"/>
              </router-link>
            </template>

            <router-link class="myClass" :to="$route.path + `live/${map.id}`">
              <img v-if="map.icon_url" :src="map.icon_url | asset(200, 200, true)" class="cardimage">
              <div v-else class="emptymap"></div>
            </router-link>
            <div style="padding: 14px;">
              <router-link class="myClass" :to="$route.path + `live/${map.id}`">
                <span>{{ map.name }}</span>
              </router-link>
            </div>
          </el-card>
        </div>
      </div>

    </el-col>
  </el-row>
</template>

<script>
import merge from 'lodash/merge'

export default {
  name: 'AppMaps',
  props: ['appID'],

  data () {
    return {
      loading: true,
      filterText: ''
    }
  },

  computed: {
    mapsFiltered () {
      if (this.filterText.length === 0) return this.maps
      return this.maps.filter(entry => {
        return entry.name.indexOf(this.filterText) !== -1
      })
    },
    maps () {
      return this.$store.state.maps
    }
  },

  methods: {
    createMap () {
      this.$prompt('Titre de la carte', 'Ajouter une carte', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        inputPattern: /^[\w][\w ]*[\w]$/,
        inputErrorMessage: 'Nom de carte invalide'
      }).then(value => {
        let payload = {
          appID: this.$route.params.appID,
          payload: {
            name: value.value,
            translated_fields: {
              'title': {
                'fr': value.value
              }
            }
          }
        }
        this.$store.dispatch('createMap', payload).then(map => {
          this.$router.push({
            name: 'applivemap',
            params: merge(this.$route.params, {mapID: map.id})
          })
        }).catch(error => {
          this.$notify({
            title: 'Echec création',
            message: `Erreur de création de la carte (${error})`,
            type: 'error'
          })
        })
      }).catch(function () {})
    },

    filterTable () {
      const rows = this.$refs.tableRef.$refs.bodyWrapper.getElementsByClassName(
        'el-table__row'
      )
      for (let row of rows) {
        let cells = row.getElementsByTagName('td')
        for (let cell of cells) {
          let innerText = cell.innerText.toLowerCase()
          let filterText = this.filterText.toLowerCase()
          if (innerText.indexOf(filterText) > -1) {
            row.style.display = ''
            break
          } else {
            row.style.display = 'none'
          }
        }
      }
    }
  },

  async created () {
    await this.$store.dispatch('ensureApp', {
      appID: this.appID
    })
    await this.$store.dispatch('loadMaps', {
      appID: this.appID
    })
    this.loading = false
  },

  mounted () {
    if (this.$refs.filter !== undefined) {
      this.$refs.filter.focus()
    }
  }
}
</script>

<style scoped>
img.mapasset {
  max-height: 48px;
  max-width: 48px;
}
.right {
  text-align: right;
}

.el-col.right .el-input {
  width: auto;
}
.cardimage {
  width: 100%;
  display: block;
  height: 200px;
  object-fit: cover;
}
.el-card {
  height: 270px;
}
.mapcards {
  display: flex;
  flex-wrap: wrap;
}
.mapcard {
  width: 200px;
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px;
}
.emptymap {
  width: 200px;
  height: 200px;
  background-color: #f0f0f0;
}
</style>
