<template>
  <el-row type="flex" justify="center" id="content-wrapper" :class="embed?'embed':''">
    <el-col :md="20" :lg="16" :xl="10" id="content">
    <h2>{{ $t('title.appmap') }}: {{ mapTitle }}</h2>

    <el-row v-loading="loading">
      <el-tabs :type="embed?'':'border-card'">

          <el-tab-pane :label="$t('g.section_general')">
            <label class="form-item">{{ $t("g.name") }}</label>
            <el-row>
              <el-input
                ref="mapName"
                v-model="currentMap.name"
                @change="updateField('name', $event, 'mapName')"
                type='text'>
              </el-input>
            </el-row>

            <label class="form-item">{{ $t("g.icon_url") }}</label>

            <DropZone
              :value="currentMap.icon_url"
              :action="`${$config.baseUrl}/upload/`"
              :data="uploadIconData"
              dropmsg="Charger une icône"
              @change="updateField('icon_url', $event)"
            />

            <el-row>
              <el-col :span="8">
                <el-card :body-style="{ padding: '0px' }" v-if="currentMap.icon_url">
                  <img :src="currentMap.icon_url | asset(256, 256)" class="image">
                </el-card>
              </el-col>
            </el-row>

            <div v-for="field in map_default_translated_fields" v-bind:key="field.id">
              <label class="form-item">{{ tr(field.displayed_name) }}</label>
              <TranslatedField
                v-model="currentMap.translated_fields[field.field_name]"
                :fieldType="$store.getters.getFieldType('map_default_translated_fields', field.field_name)"
                @change="updateTranslatedField(field.field_name, $event)"
              />
            </div>

            <template v-if="currentAppState">
              <div v-for="field in map_default_content_fields" v-bind:key="field.id">
                <label class="form-item">{{ tr(field.displayed_name) }}</label>
                <ContentField
                  v-model="currentMap.content_fields[field.field_name]"
                  :fieldDef="field"
                  @change="updateContentField(field.field_name, $event)"
                />
              </div>
            </template>

        </el-tab-pane>

        <el-tab-pane :label="$t('g.section_map')">
          <h5>{{ $t("g.source_url") }}</h5>

          <DropZone
            :value="currentMap.source_url"
            :action="`${$config.baseUrl}/upload/`"
            :data="uploadMapData"
            dropmsg="Charger une carte"
            @change="updateField('source_url', $event)"
          />

          <el-row v-if="currentMap.is_indoor">
            <el-col :span="8">
              <el-card :body-style="{ padding: '0px' }" v-if="currentMap.source_url">
                <img :src="currentMap.source_url | asset(512, 512)" class="image">
              </el-card>
            </el-col>
          </el-row>

          <el-row>
            <el-checkbox
              :value="currentMap.is_indoor"
              @change="updateField('is_indoor', $event)">Indoor</el-checkbox>
          </el-row>

          <h5>{{ $t("g.zoom") }}</h5>

          <el-row>
            <el-col :sm="6">
              <label class="form-item">{{ $t("g.zoom_default")}}</label>
            </el-col>
            <el-col :sm="18">
              <el-input-number
                :value="currentMap.default_zoom"
                @change="updateField('default_zoom', $event)"
                :max="24"
                type='text'>
              </el-input-number>
            </el-col>
          </el-row>

          <el-row>
            <el-col :sm="6">
              <label class="form-item">{{ $t("g.minimum")}}</label>
            </el-col>
            <el-col :sm="18">
              <el-input-number
                :value="currentMap.min_zoom"
                @change="updateField('min_zoom', $event)"
                :max="24"
                type='text'>
              </el-input-number>
            </el-col>
          </el-row>

          <el-row>
            <el-col :sm="6">
              <label class="form-item">{{ $t("g.maximum")}}</label>
            </el-col>
            <el-col :sm="18">
              <el-input-number
                :value="currentMap.max_zoom"
                @change="updateField('max_zoom', $event)"
                :max="24"
                type='text'>
              </el-input-number>
            </el-col>
          </el-row>

          <h5>{{ $t("g.center") }}</h5>

          <el-row>
            <el-checkbox v-model="autoCenter">{{ $t('g.by_default') }}</el-checkbox>
          </el-row>

          <el-row>
            <el-col :sm="6">
              <label class="form-item">{{ $t(`g.indoor[${this.currentMap.is_indoor}].lat`)}}</label>
            </el-col>
            <el-col :sm="18">
              <el-input
                v-model="currentMap.center_lat"
                @change="updateFieldAsNumeric('center_lat', $event)"
                :disabled="autoCenter"
                type='number'>
              </el-input>
            </el-col>
          </el-row>

          <el-row>
            <el-col :sm="6">
              <label class="form-item">{{ $t(`g.indoor[${this.currentMap.is_indoor}].lon`)}}</label>
            </el-col>
            <el-col :sm="18">
              <el-input
                v-model="currentMap.center_lon"
                @change="updateFieldAsNumeric('center_lon', $event)"
                :disabled="autoCenter"
                type='number'>
              </el-input>
            </el-col>
          </el-row>

          <h5>{{ $t("g.bounds") }}</h5>

          <el-row>
            <el-checkbox v-model="useBounds">{{ $t('g.nobounds') }}</el-checkbox>
          </el-row>

          <el-row>
            <el-col :sm="6">
              <label class="form-item">{{ $t(`g.indoor[${this.currentMap.is_indoor}].min_lon`)}}</label>
            </el-col>
            <el-col :sm="18">
              <el-input
                v-model="bounds.min_lon"
                @change="updateBounds('min_lon', $event)"
                :disabled="useBounds"
                type='number'>
              </el-input>
            </el-col>
          </el-row>
          <el-row>
            <el-col :sm="6">
              <label class="form-item">{{ $t(`g.indoor[${this.currentMap.is_indoor}].min_lat`)}}</label>
            </el-col>
            <el-col :sm="18">
              <el-input
                v-model="bounds.min_lat"
                @change="updateBounds('min_lat', $event)"
                :disabled="useBounds"
                type='number'>
              </el-input>
            </el-col>
          </el-row>
          <el-row>
            <el-col :sm="6">
              <label class="form-item">{{ $t(`g.indoor[${this.currentMap.is_indoor}].max_lon`)}}</label>
            </el-col>
            <el-col :sm="18">
              <el-input
                v-model="bounds.max_lon"
                @change="updateBounds('max_lon', $event)"
                :disabled="useBounds"
                type='number'>
              </el-input>
            </el-col>
          </el-row>
          <el-row>
            <el-col :sm="6">
              <label class="form-item">{{ $t(`g.indoor[${this.currentMap.is_indoor}].max_lat`) }}</label>
            </el-col>
            <el-col :sm="18">
              <el-input
                v-model="bounds.max_lat"
                @change="updateBounds('max_lat', $event)"
                :disabled="useBounds"
                type='number'>
              </el-input>
            </el-col>
          </el-row>

        </el-tab-pane>

        <el-tab-pane v-if="this.$store.state.user.is_staff" :label="$t('g.section_debug')">
          <h2>Current map</h2>
          <pre>{{ currentMap }}</pre>
          <h2>Generic map</h2>
          <pre>{{ currentMapState }}</pre>
          <h2>App</h2>
          <pre>{{ currentAppState }}</pre>
        </el-tab-pane>
      </el-tabs>
    </el-row>

  </el-col>
  </el-row>

</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import Vue from 'vue'
import TranslatedField from './TranslatedField'
import ContentField from './ContentField'
import DropZone from './DropZone'

export default {
  name: 'AppMap',
  components: { TranslatedField, ContentField, DropZone },
  props: {
    appID: {
      type: Number
    },
    mapID: {
      type: Number
    },
    embed: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      allowCommit: false,
      loading: true,
      iconUploadVisible: false,
      iconLoading: false,
      mapUploadVisible: false,
      mapLoading: false,
      map_default_translated_fields: [],
      map_default_content_fields: [],
      bounds: {
        min_lat: 0,
        min_lon: 0,
        max_lat: 0,
        max_lon: 0
      },
      currentMap: {
        is_indoor: false
      }
    }
  },

  methods: {

    async commit (field, value, ref) {
      let el = this.$refs[ref]
      if (el !== undefined) el.$el.classList.add('updating')
      await this.$store.dispatch('updateCurrentMap', {
        [field]: value
      })
      if (el !== undefined) el.$el.classList.remove('updating')
    },

    async updateField (field, value, el) {
      Vue.set(this.currentMap, field, value)
      await this.commit(field, value, el)

      if (field === 'source_url' && this.currentMap.icon_url === '') {
        // if there is no icon, also set the default icon, only if it's not a tile url
        if (value.length !== 0 && value.indexOf('{z}') === -1) {
          await this.updateField('icon_url', value, el)
        }
      }
    },

    async updateFieldAsNumeric (field, value, el) {
      try {
        value = parseFloat(value)
      } catch (e) {
        value = null
      }
      await this.updateField(field, value, el)
    },

    updateBounds (field, value, el) {
      let payload = cloneDeep(this.bounds)
      try {
        value = parseFloat(value)
      } catch (e) {
        value = null
      }
      payload[field] = value
      Vue.set(this, 'bounds', payload)
      Vue.set(this.currentMap, 'bounds', payload)
      this.commit('bounds', payload, el)
    },

    updateContentField (field, value, el) {
      let payload = this.currentMap.content_fields
      payload[field] = value
      Vue.set(this.currentMap, 'content_fields', payload)
      this.commit('content_fields', payload, el)
    },

    updateTranslatedField (field, value, el) {
      let payload = this.currentMap.translated_fields
      if (typeof payload === 'undefined') payload = {}
      payload[field] = value
      Vue.set(this.currentMap, 'translated_fields', payload)
      this.commit('translated_fields', payload, el)
    },
    reload () {
      this.currentMap = cloneDeep(this.$store.state.currentMap)
      if (typeof this.currentMap.bounds !== 'undefined' && this.currentMap.bounds !== null) {
        this.bounds = cloneDeep(this.currentMap.bounds)
      }
      // fill the map with all the translated_fields and default_fields
      for (let field of this.$store.state.app.fields.map_default_translated_fields) {
          if (this.currentMap.translated_fields[field.field_name] === undefined) {
            this.currentMap.translated_fields[field.field_name] = {}
          }
      }

      this.map_default_content_fields = this.$store.state.app.fields.map_default_content_fields
      this.map_default_translated_fields = this.$store.state.app.fields.map_default_translated_fields
    }
  },

  computed: {
    languages () {
      return this.$store.state.languages
    },
    uploadIconData () {
      return {
        application_id: this.currentMap.application,
        map_id: this.currentMap.id,
        intent: 'map-icon'
      }
    },
    uploadMapData () {
      return {
        application_id: this.currentMap.application,
        map_id: this.currentMap.id,
        intent: 'map-source'
      }
    },
    useBounds: {
      get: function () {
        if (typeof this.currentMap.bounds !== 'undefined') {
          return (this.currentMap.bounds === null || Object.entries(this.currentMap.bounds).length === 0)
        } else {
          return this.$store.state.currentMap.bounds === null
        }
      },
      set: function (value) {
        if (!value) {
          if (this.bounds.min_lat === undefined) this.bounds.min_lat = 0
          if (this.bounds.max_lat === undefined) this.bounds.max_lat = 0
          if (this.bounds.min_lon === undefined) this.bounds.min_lon = 0
          if (this.bounds.max_lon === undefined) this.bounds.max_lon = 0
          this.updateField('bounds', this.bounds)
        } else {
          this.updateField('bounds', {})
        }
      }
    },
    autoCenter: {
      get: function () {
        if (typeof this.currentMap.center_lat !== 'undefined') {
          return this.currentMap.center_lat === null
        } else {
          return this.$store.state.currentMap.center_lat === null
        }
      },
      set: function (value) {
        if (!value) {
          this.updateField('center_lon', 0)
          this.updateField('center_lat', 0)
        } else {
          this.updateField('center_lon', null)
          this.updateField('center_lat', null)
        }
      }
    },
    currentAppState () {
      return this.$store.state.app
    },
    currentMapState () {
      return this.$store.state.currentMap
    },
    mapTitle () {
      return this.$store.getters.getMapTitle
    }
  },

  async mounted () {
    await this.$store.dispatch('ensureApp', {
      appID: this.appID
    })
    await this.$store.dispatch('ensureMap', {
      appID: this.appID,
      mapID: this.mapID
    })

    this.reload();
    this.loading = false
  },

}
</script>

<style scoped>
#content-wrapper.embed h2 {
  display: none;
}
#content-wrapper.embed #content {
  width: 100%;
}
.image {
  width: 100%;
  display: block;
}

</style>
