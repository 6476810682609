<template>
  <div class="">
    <h2>Catégories</h2>
    <el-alert v-if="missingCategoryNameField"
        title="Champ 'name' manquant dans les champs de catégories"
        :closable="false"
        :show-icon="true"
        type="error">
        <div class="alert-description">
            Les champs des catégories avec traductions (translated_fields) n'ont pas de champ nommé 'name'.
            <br/>
            Il est impératif de corriger la situation avant d'utiliser cette interface de configuration.
            <br/><br/>
            <router-link :to="{name: 'appsettings', params: $route.params}">Configurer l'application</router-link>
        </div>
    </el-alert>

    <el-dialog title="Edition de la catégorie" :visible.sync="editVisible" v-if="currentCategory">
        <span>
            <el-tabs>
              <el-tab-pane :label="$t('g.section_fields')">
                <div v-for="field in category_default_translated_fields" v-bind:key="field.id">
                    <label class="el-form-item__label">{{ tr(field.displayed_name) }}</label>
                    <TranslatedField
                    v-if="currentCategory.translated_fields"
                    v-model="currentCategory.translated_fields[field.field_name]"
                    :fieldType="field.type"
                    @change="updateTranslatedField(field.field_name, $event)"
                    />
                </div>

                <div v-for="field in category_default_content_fields" v-bind:key="field.id">
                    <label class="form-item">{{ tr(field.displayed_name) }}</label>
                    <ContentField
                    v-model="currentCategory.content_fields[field.field_name]"
                    :fieldDef="field"
                    @change="updateContentField(field.field_name, $event)"
                    />
                </div>
              </el-tab-pane>
              <el-tab-pane :label="$t('g.section_general')">
                <el-row>
                    <el-col :sm="6">
                    <label class="form-item">{{ $t("g.order")}}</label>
                    </el-col>
                    <el-col :sm="18">
                    <el-input-number
                        :value="currentCategory.order"
                        :min="0"
                        @change="updateField('order', $event, true)"
                        type='text'>
                    </el-input-number>
                    </el-col>
                </el-row>

                <label class="form-item">{{ $t("g.icon_url") }}</label>
                <DropZone
                :value="currentCategory.icon_url"
                :action="`${$config.baseUrl}/upload/`"
                :data="uploadIconData"
                dropmsg="Charger une icône"
                @change="updateField('icon_url', $event)"
                />

                <el-card class="card" :body-style="{ padding: '0px' }" v-if="currentCategory.icon_url">
                    <img :src="currentCategory.icon_url | asset(64, 64)" class="image">
                </el-card>

              </el-tab-pane>
            </el-tabs>
        </span>
        <span slot="footer" class="dialog-footer">
            <el-button type="danger" v-if="!currentCategory.is_root"
                @click="() => remove(currentCategory)">Supprimer la catégorie</el-button>
            <el-button @click="editVisible = false">Fermer</el-button>
        </span>
    </el-dialog>

    <el-dialog title="Nouvelle catégorie" :visible.sync="newVisible" v-if="newCategory">
        <span>
            <div v-for="field in category_default_translated_fields" v-bind:key="field.id">
                <label class="el-form-item__label">{{ tr(field.displayed_name) }}</label>
                <TranslatedField
                v-if="newCategory.translated_fields"
                v-model="newCategory.translated_fields[field.field_name]"
                :fieldType="field.type"
                @change="newCategory.translated_fields[field.field_name] = $event"
                />
            </div>

            <div v-for="field in category_default_content_fields" v-bind:key="field.id">
                <label class="form-item">{{ tr(field.displayed_name) }}</label>
                <ContentField
                v-model="newCategory.content_fields[field.field_name]"
                :fieldDef="field"
                @change="newCategory.content_fields[field.field_name] = $event"
                />
            </div>
        </span>
        <span slot="footer" class="dialog-footer">
            <el-button @click="() => addConfirm()" :disabled="!newCategory.translated_fields.name.fr">Ajouter</el-button>
        </span>
    </el-dialog>

    <el-tree
        v-loading="loading"
        v-if="!missingCategoryNameField"
        :data="[categories]"
        node-key="id"
        default-expand-all
        :expand-on-click-node="false">
        <span class="custom-tree-node" slot-scope="{ node, data }">
            <span @click="() => edit(data)">
                <img v-if="data.icon_url" :src="data.icon_url | asset(64, 64)" class="icon-image">
                {{ tf(data, 'name') || '(empty field)' }}
            </span>
            <span>
                <el-tag type="info" size="mini">ID {{data.id}}</el-tag>
                <el-button size="mini" type="text"
                    @click="() => add(data)">Ajouter</el-button>
            </span>
        </span>
    </el-tree>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import TranslatedField from './TranslatedField'
import ContentField from './ContentField'
import Vue from 'vue'
import DropZone from './DropZone'

export default {
  name: 'AppCategories',
  props: ['appID'],
  components: { TranslatedField, ContentField, DropZone },
  data () {
    return {
      categories: {
        id: -1,
        translated_fields: {
            name: {fr: 'Racine', en: 'Root'}
        },
        children: [],
        is_root: true
      },
      currentCategory: null,
      newCategory: null,
      newCategoryParent: null,
      category_default_translated_fields: [],
      category_default_content_fields: [],
      loading: true,
      editVisible: false,
      newVisible: false,
      missingCategoryNameField: null
    }
  },

  computed: {
    uploadIconData () {
      return {
        application_id: this.appID,
        category_id: this.currentCategory.id,
        intent: 'app-media'
      }
    }
  },

  methods: {
    add (node) {
        let newCategory = {
            translated_fields: {},
            content_fields: {},
            children: [],
            order: 0,
            icon_url: ''
        }
        for (let field of this.$store.state.app.fields.category_default_translated_fields) {
            if (newCategory.translated_fields[field.field_name] === undefined) {
                newCategory.translated_fields[field.field_name] = {}
            }
        }
        this.newCategory = newCategory
        this.newCategoryParent = node
        this.newVisible = true
    },

    async addConfirm () {
        this.newCategoryParent.children.push(this.newCategory)
        let payload = cloneDeep(this.newCategory)
        payload.parentId = this.newCategoryParent.id
        await this.$store.dispatch('createCategory', payload)
        this.newCategory.id = this.$store.state.currentCategory.id
        this.newVisible = false
    },

    edit (node) {
        if (node.is_root) return
        this.currentCategory = node
        for (let field of this.$store.state.app.fields.category_default_translated_fields) {
            if (node.translated_fields[field.field_name] === undefined) {
                node.translated_fields[field.field_name] = {}
            }
        }
        this.editVisible = true
    },

    async remove () {
      await this.$confirm(
        'Vous allez supprimer la catégorie de manière définitive. Continuez?',
        'Attention !', {
          confirmButtonText: 'Oui, je supprime!',
          cancelButtonText: 'Annuler',
          type: 'warning'
        }).then(async () => {
            this.loading = true
            await this.$store.dispatch('deleteCategory', {id: this.currentCategory.id})
            this.editVisible = false
            this.currentCategory = null
            await this.$store.dispatch('loadCategories', {
                appID: this.$route.params.appID
            })
            this.categories.children = this.$store.state.categories
            this.loading = false
            this.$message({
                message: 'La catégorie a été supprimée',
                type: 'success'
            })
        })
    },

    async commit (field, value) {
        await this.$store.dispatch('updateCategory', {
            id: this.currentCategory.id,
            [field]: value
        })
    },

    async updateField (field, value, reload) {
        if (reload === true) {
            this.loading = true
        }
        Vue.set(this.currentCategory, field, value)
        await this.commit(field, value)
        if (reload === true) this.reload()
    },

    async reload () {
        this.loading = true
        await this.$store.dispatch('loadCategories', {
            appID: this.appID
        })
        this.categories.children = this.$store.state.categories
        this.loading = false
    },

    updateTranslatedField (field, value, lang) {
        let payload = this.currentCategory.translated_fields
        if (typeof payload === 'undefined') {
            payload = {}
        }

        if (lang === undefined) {
            payload[field] = value
        } else {
            let payloadField = payload[field]
            if (typeof payloadField === 'undefined') {
            payload[field] = {
                fr: '',
                en: ''
            }
            payloadField = payload[field]
            }
            payloadField[lang] = value
        }

        Vue.set(this.currentCategory, 'translated_fields', payload)
        this.commit('translated_fields', payload)
    },

    updateContentField (field, value) {
        let payload = this.currentCategory.content_fields
        payload[field] = value
        Vue.set(this.currentCategory, 'content_fields', payload)
        this.commit('content_fields', payload)
    }

  },

  async mounted () {
    await this.$store.dispatch('ensureApp', {
      appID: this.appID
    })
    await this.$store.dispatch('loadCategories', {
      appID: this.appID
    })
    this.categories.children = this.$store.state.categories
    this.category_default_content_fields = this.$store.state.app.fields.category_default_content_fields
    this.category_default_translated_fields = this.$store.state.app.fields.category_default_translated_fields
    this.missingCategoryNameField = null
    for (let field of this.$store.state.app.fields.category_default_translated_fields) {
        if (field.field_name === 'name') this.missingCategoryNameField = false
    }
    if (this.missingCategoryNameField === null) this.missingCategoryNameField = true
    this.loading = false
  }
}
</script>

<style scoped>
.custom-tree-node {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding-right: 8px;
}

.card {
    width: 64px;
}
.icon-image {
    width: 24px;
    vertical-align: middle;
}
.alert-description {
    font-size: 14px;
    padding: 5px 0;
}
</style>

<style>
.el-dialog__body {
    padding-top: 0px;
    padding-bottom: 0px;
}
</style>
