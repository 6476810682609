<template>
  <div class="">
    <h2>{{ appTitle }}</h2>

    <ul class="be">
      <li>
        <router-link :to="{name: 'appmaps', params: $route.params}">
          <div class="be-icon">
            <font-awesome-icon icon="map" />
          </div>
          <div class="be-name">
            Cartes
          </div>
        </router-link>
      </li>
      <li>
        <router-link :to="{name: 'appselections', params: $route.params}">
          <div class="be-icon">
            <font-awesome-icon icon="bookmark" />
          </div>
          <div class="be-name">
            Sélections
          </div>
        </router-link>
      </li>
      <li>
        <router-link :to="{name: 'appcategories', params: $route.params}">
          <div class="be-icon">
            <font-awesome-icon icon="sitemap" />
          </div>
          <div class="be-name">
            Catégories
          </div>
        </router-link>
      </li>
      <li>
        <router-link :to="{name: 'appusers', params: $route.params}">
          <div class="be-icon">
            <font-awesome-icon icon="users" />
          </div>
          <div class="be-name">
            Utilisateurs
          </div>
        </router-link>
      </li>
      <li>
        <router-link :to="{name: 'applayouts', params: $route.params}">
          <div class="be-icon">
            <font-awesome-icon icon="mobile-alt" />
          </div>
          <div class="be-name">
            Layouts
          </div>
        </router-link>
      </li>
      <li>
        <router-link :to="{name: 'appsettings', params: $route.params}">
          <div class="be-icon">
            <font-awesome-icon icon="cog" />
          </div>
          <div class="be-name">
            Paramètres
          </div>
        </router-link>
      </li>
    </ul>

  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'AppIndex',
  props: ['appID'],
  data () {
    return {
      currentApp: null,
      loading: true
    }
  },

  computed: {
    appTitle () {
      return this.$store.getters.getAppTitle
    }
  },

  async mounted () {
    await this.$store.dispatch('ensureApp', {
      appID: this.appID
    })
    this.currentApp = cloneDeep(this.$store.state.currentApp)
    this.loading = false
  }
}
</script>

<style scoped>

.be {
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
}

.be li {
  display: flex;
  flex-direction: column;
  width: 140px;
  height: 160px;
  border: 1px solid #e4e7ed;
  border-radius: 4px;
  margin-right: 40px;
  margin-bottom: 40px;
  -webkit-box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  box-shadow: 0 2px 12px 0 rgba(0,0,0,.1);
  transition-property: background-color, border-color;
  transition-duration: .15s;
  padding: 0px !important;
}

.be li a {
  transition-property: color;
  transition-duration: .15s;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.be-icon {
  flex-grow: 1;
  text-align: center;
  vertical-align: middle;
  font-size: 64px;
  padding: 15px 10px;
}

.be-name {
  line-height: 24px;
  text-align: center;
  padding: 10px;
  border-top: 1px solid #e4e7ed;
}

.be li:hover {
    background-color: #409eff;
    border-color: #409eff;
}

.be li:hover a {
    color: #ffffff;
}

.be li .be-icon {
  background-color: white;
  color: #c5c7ca;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
</style>
