<template>
  <el-row v-if="localValue" :gutter="gutter">
    <el-col :sm="span" v-for="lang in $store.state.languages" v-bind:key="lang">
      <el-input
        v-model="localValue[lang]"
        v-if="fieldType === 'string'"
        @change="$emit('change', localValue)">
        <template slot="append"><flag :iso="lang"/></template>
      </el-input>
      <div v-else class="textarea">
        <div class="textarea-flag">
          <flag :iso="lang"/>
        </div>
        <div v-if="htmlTagsDetected && htmlTagsDetected[lang]" class="textarea-info">Tags HTML détectés</div>
        <editor-menu-bubble
            :editor="editor[lang]"
            :keep-in-bounds="keepInBounds"
            v-slot="{ commands, isActive, menu }">
          <div
            class="menububble"
            :class="{ 'is-active': menu.isActive }"
            :style="`left: ${menu.left}px; bottom: ${menu.bottom}px;`"
          >

            <button
              class="menububble__button"
              :class="{ 'is-active': isActive.bold() }"
              @click="commands.bold"
            >
              <font-awesome-icon icon="bold" />
            </button>

            <button
              class="menububble__button"
              :class="{ 'is-active': isActive.italic() }"
              @click="commands.italic"
            >
              <font-awesome-icon icon="italic" />
            </button>

            <button
              class="menububble__button"
              :class="{ 'is-active': isActive.sup() }"
              @click="commands.sup"
            >
              <font-awesome-icon icon="superscript" />
            </button>

            <button
              class="menububble__button"
              :class="{ 'is-active': isActive.sub() }"
              @click="commands.sub"
            >
              <font-awesome-icon icon="subscript" />
            </button>

            <button
              class="menububble__button"
              :class="{ 'is-active': isActive.heading(1) }"
              @click="commands.heading(1)"
            >
              <font-awesome-icon icon="1" />
            </button>

          </div>
        </editor-menu-bubble>

        <editor-content
          :editor="editor[lang]"
          class="editor"/>
        <!-- <template v-else> -->
          <!-- <el-input type="textarea"
            v-model="localValue[lang]"
            rows="5"
            @change="$emit('change', localValue)"/> -->
        <!-- </template> -->
      </div>
    </el-col>

  </el-row>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import Flag from './Flag'
import { Editor, EditorContent, EditorMenuBubble } from 'tiptap'
import {
  Bold,
  Italic,
  Heading,
} from 'tiptap-extensions'
import { Sub } from './marks/Sub'
import { Sup } from './marks/Sup'
import Vue from 'vue'

export default {
  name: 'TranslatedField',
  props: {
    value: Object,
    gutter: {
      type: Number,
      default: 0
    },
    span: {
      type: Number,
      default: 24
    },
    fieldType: {
      type: String,
      default: 'string'
    }
  },
  components: { Flag, EditorContent, EditorMenuBubble },
  data () {
    return {
      keepInBounds: true,
      htmlTagsDetected: {},
      editor: {},
      localValue: {}
    }
  },
  methods: {
    loadEditor () {
      for (const lang of this.$store.state.languages) {
        let editor = new Editor({
          onBlur: () => this.onEditorBlur(lang),
          extensions: [
            new Bold(),
            new Italic(),
            new Sub(),
            new Sup(),
            new Heading({
              levels: [1, 2, 3]
            }),
          ],
          content: this.localValue[lang] || ''
        })
        this.editor[lang] = editor
      }
    },
    unloadEditor () {
      for (const lang of this.$store.state.languages) {
        this.editor[lang].destroy()
      }
    },
    onEditorBlur (lang) {
      var html = this.editor[lang].getHTML().trim()

      // trim outstanding <p> tags if there is no other <p> tags within
      var html2 = html.replace(/<p>(.*)<\/p>/, '$1')
      if (html2.indexOf('<p>') === -1) {
        html = html2
      }
      Vue.set(this.htmlTagsDetected, lang, html.indexOf('</') !== -1)
      if (html !== this.localValue[lang]) {
        this.localValue[lang] = html
        this.$emit('change', this.localValue)
      }
    }
  },
  computed: {
    inputType () {
      if (this.fieldType === 'longstring') return 'textarea'
      return 'string'
    }
  },
  mounted () {
    this.localValue = cloneDeep(this.value)
    this.loadEditor()
  },
  beforeDestroy () {
    this.unloadEditor()
  },
  watch: {
    value () {
      // reload only if the local value changed?
      let changed = false
      for (const lang of this.$store.state.languages) {
        if (this.localValue[lang] !== this.value[lang]) {
          changed = true
        }
      }
      if (changed) {
        this.localValue = cloneDeep(this.value)
        for (const lang of this.$store.state.languages) {
          const html = this.localValue[lang] || ''
          this.editor[lang].setContent(html)
          this.htmlTagsDetected[lang] = html.indexOf('</') !== -1
        }
      }
    }
  }
}
</script>

<style scoped>
.textarea-flag {
  float: right;
  margin-top: 5px;
  margin-bottom: -2px;
  border: 1px solid #dcdfe6;
  border-bottom: none;
  background-color: #f5f7fa;
  padding: 5px 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.textarea-info {
  float: right;
  margin-top: 5px;
  margin-bottom: -2px;
  font-size: 10px;
  display: flex;
  align-items: center;
  height: 30px;
  padding: 10px;
  font-style: italic;
  color: #8492a6;
}

.editor {
}

.menububble {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    z-index: 20;
    background: #000;
    border-radius: 5px;
    padding: .3rem;
    margin-bottom: .5rem;
    padding: 4px;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity .2s,visibility .2s;
    transition: opacity .2s,visibility .2s;
}
.menububble.is-active {
  visibility: visible;
  opacity: 1;
}
.menububble__button {
  background: transparent;
  color: white;
  border: none;
  padding: 4px 10px;
}
.menububble__button.is-active {
  color: black;
  background: white;
}

</style>

<style>

.ProseMirror {
  clear: both;
  font-size: 14px;
  padding: 10px 15px;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
}

.ProseMirror:focus {
  border: 1px solid #409eff !important;
  outline: none;
}

</style>
