<template>
  <el-row v-loading="uploading">
  <form class="dropzone" method="post" action="" enctype="multipart/form-data" ref="form">
    <div class="dropzone__input">
      <input
        class="dropzone__file"
        ref="dropzone_input"
        type="file"
        name="files[]"
        @change="startUpload($event.target.files)"
      />
      <!-- <input
        class="dropzone__file"
        ref="dropzone_input"
        type="file"
        name="files[]"
        id="file"
        data-multiple-caption="{count} files selected"
        multiple
      /> -->
    </div>
    <div class="dropzone__infoupload">
      <i class="el-icon-upload"></i>
      {{ dropmsg }}
    </div>
    <el-input
      class="dropzone__elinput"
      v-model="dataValue"
      @change="dispatchUpload($event)"
      type="text">
      <el-button
        slot="append"
        icon="el-icon-upload"
        @click="$refs.dropzone_input.click()"
      ></el-button>
    </el-input>
  </form>
  </el-row>
</template>

<script>
// import cloneDeep from 'lodash/cloneDeep'
import axios from 'axios'
import { config } from '../config'

export default {
  name: 'DropZone',
  props: {
    value: {},
    data: Object,
    action: String,
    dropmsg: {
      type: String,
      default: 'Charger un média'
    }
  },
  data () {
    return {
      dataValue: this.value,
      uploading: false
    }
  },
  components: { },
  computed: {
    isAdvancedUpload () {
      var div = document.createElement('div')
      return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window
    }
  },
  methods: {
    resetUploader () {
      this.$refs.dropzone_input.value = '';
    },

    async getMeta (url) {
        return new Promise((resolve, reject) => {
            let img = new Image()
            img.onload = () => resolve(img)
            img.onerror = reject
            img.src = url
        })
    },

    async dispatchUpload (filename) {
      this.getMeta(config.proxyUrl + '/' + filename).then((img) => {
        this.$emit('metadata', {
          width: img.width,
          height: img.height
        })
      }).catch((data) => {
      }).finally(() => {
        this.$emit('change', filename)
        this.uploading = false
        this.$refs.form.classList.remove('is-uploading')
      })
    },

    async startUpload (droppedFiles) {
      var ajaxData = new FormData()

      if (droppedFiles.length >= 1) {
        ajaxData.append('file', droppedFiles[0])
      }
      if (this.data !== undefined) {
        for (let [key, value] of Object.entries(this.data)) {
          ajaxData.append(key, value)
        }
      }
      this.$emit('beforeUpload')
      this.uploading = true
      if (this.$store.state.readonly) {
        this.uploading = false
        this.$refs.form.classList.remove('is-uploading')
        this.$notify({
          title: 'Echec d\'upload',
          message: `Erreur lors de l'upload du média (lecture seule)`,
          type: 'error'
        })
      }

      axios({
        url: this.action,
        data: ajaxData,
        method: 'post'
      }).then((response) => {
        this.dispatchUpload(config.bucketUrl + '/' + response.data.filename)
        this.resetUploader()
      }).catch((data) => {
        this.uploading = false
        this.$refs.form.classList.remove('is-uploading')
        this.resetUploader()
        this.$notify({
          title: 'Echec d\'upload',
          message: `Erreur lors de l'upload du média (${data})`,
          type: 'error'
        })
      })
    }
  },
  mounted () {
    let $form = this.$refs.form
    if (this.isAdvancedUpload) {
      $form.classList.add('has-advanced-upload')
    }

    let count = 0
    for (let name of ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop']) {
      document.addEventListener(name, (e) => {
        // e.preventDefault()
        // e.stopPropagation()
        if (name === 'dragenter') {
          count += 1
        } else if (name === 'dragleave') {
          count -= 1
        } else if (name === 'drop') {
          count = 0
        }
        if (count) {
          document.body.classList.add('is-dragging')
        } else {
          document.body.classList.remove('is-dragging')
        }
      })
      $form.addEventListener(name, (e) => {
        e.preventDefault()
        e.stopPropagation()
      })
    }

    // document.body.addEventListener('drag', function () {
    // })

    $form.addEventListener('dragstart', function (e) {
      $form.classList.add('is-dragging')
    })

    for (let name of ['dragover', 'dragenter']) {
      $form.addEventListener(name, function (e) {
        $form.classList.add('is-dragover')
      })
    }

    for (let name of ['dragleave', 'dragend', 'drop']) {
      $form.addEventListener(name, function (e) {
        $form.classList.remove('is-dragover')
        $form.classList.remove('is-dragging')
      })
    }

    $form.addEventListener('drop', (e) => {
      this.startUpload(e.dataTransfer.files)
    })
  },
  watch: {
    value: function (newVal, oldVal) {
      this.dataValue = newVal
    }
  }
}
</script>

<style scoped>

.dropzone {
}

.dropzone .dropzone__infoupload {
  display: none;
}

.dropzone.is-dragover {
  background-color: #409EFF;
}
.dropzone__input {
  visibility: hidden;
  position: absolute;
}
.dropzone.is-uploading .dropzone__uploading {
  display: block;
}

</style>

<style>
.is-dragging .dropzone {
  background-color: #E6A23C;
}
.dropzone.is-dragover .dropzone__elinput,
.is-dragging .dropzone .dropzone__elinput {
  visibility: hidden;
}
.dropzone.is-dragover,
.is-dragging .dropzone.has-advanced-upload {
  outline: 2px dashed black;
  outline-offset: -5px;
}

.dropzone.is-dragover .dropzone__infoupload,
.is-dragging .dropzone__infoupload {
  display: block !important;
  position: absolute;
  text-align: center;
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  font-size: 14px;
  padding-top: 10px;
  color: white;
}

</style>
