<template>
  <el-row v-loading="loading">
    <h2>
      {{ $t('title.appusers') }}
      <el-button v-if="$store.state.user.is_staff" size="mini" icon="el-icon-plus" circle class="adduser" @click="showCreateUser()"></el-button>
      <el-button size="mini" icon="el-icon-download" circle class="adduser" @click="exportToCsv()"></el-button>
    </h2>

    <el-dialog
      title="Création d'un utilisateur"
      :visible.sync="showCreate"
      width="30%">
      <div>
          <label class="form-item">{{ $t("g.name") }}</label>
          <el-row>
            <el-input v-model="newName" type='text'/>
          </el-row>
          <label class="form-item">{{ $t("g.email") }}</label>
          <el-row>
            <el-input v-model="newEmail" type='email'/>
          </el-row>
          <label class="form-item">{{ $t("login.password") }}</label>
          <el-row>
            <el-input v-model="newPassword" type='password'/>
          </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showCreate = false">Annuler</el-button>
        <el-button type="primary" :disabled="!canAddUser" @click="createUser()">Ajouter l'utilisateur</el-button>
      </span>
    </el-dialog>

    <div class="block">
      <el-pagination
        layout="sizes, prev, pager, next"
        :total="users.count"
        :page-sizes="[10, 25, 50, 100]"
        :page-size="25"
        @size-change="(size) => { this.limit = size; loadPage(this.page) }"
        @current-change="loadPage">
      </el-pagination>
    </div>
    <el-table :data="users.results" width="100%"  v-loading="tableLoading">
      <el-table-column prop="id" width="64">
        <template slot-scope="scope">
          <router-link class="myClass" :to="$route.path + `${scope.row.id}`">
            <el-button type="primary" icon="el-icon-edit" circle/>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column prop="email" :label="$t('g.email')" sortable/>
      <el-table-column prop="name" :label="$t('g.name')" sortable></el-table-column>
      <el-table-column prop="id" :label="$t('user.permissions')">
        <template slot-scope="scope">
          <el-tag type="danger" v-if="scope.row.is_staff">Staff</el-tag>
          <el-tag type="info" v-if="scope.row.is_moderator">Modérateur</el-tag>
          <el-tag type="success" v-if="scope.row.is_contributor">Contributeur</el-tag>
        </template>
      </el-table-column>
    </el-table>
  </el-row>
</template>

<script>

export default {
  name: 'AppUsers',
  props: ['appID'],

  data () {
    return {
      loading: false,
      tableLoading: false,
      limit: 25,
      offset: 0,
      showCreate: false,
      newEmail: '',
      newName: '',
      newPassword: '',
      users: {}
    }
  },

  computed: {
    canAddUser () {
      if (this.newEmail.length < 5) return
      if (!this.validateEmail(this.newEmail)) return
      if (this.newName.length < 3) return
      if (this.newPassword.length < 6) return
      return true
    }
  },

  methods: {
    exportToCsv () {
      this.$store.dispatch('exportUsers')
    },
    validateEmail (email) {
        // eslint-disable-next-line
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return re.test(String(email).toLowerCase())
    },
    showCreateUser () {
      this.newEmail = ''
      this.newPassword = ''
      this.newName = ''
      this.showCreate = true
    },
    createUser () {
      let payload = {
        name: this.newName,
        email: this.newEmail,
        password: this.newPassword
      }
      this.$store.dispatch('createUser', payload).then(user => {
        this.$router.push({path: this.$route.path + `${user.id}`})
        this.showCreate = false
      }).catch(error => {
        this.$notify({
          title: 'Echec création',
          message: `Erreur de création de l'utilisateur (${error})`,
          type: 'error'
        })
      })
    },

    async loadPage (page) {
      this.tableLoading = true
      this.offset = this.limit * (page - 1)
      this.users = await this.$store.dispatch('loadUsers', {
        appID: this.$route.params.appID,
        offset: this.offset,
        limit: this.limit
      })
      this.tableLoading = false
    }
  },

  async created () {
    await this.$store.dispatch('ensureApp', {
      appID: this.appID
    })
    await this.loadPage(0)
    this.loading = false
  }
}
</script>

<style scoped>
.adduser {
  float: right;
  margin-left: 10px;
}
</style>
<style>
.el-dialog__body {
    padding-top: 0px;
    padding-bottom: 0px;
}
</style>
