<template>
  <el-row v-loading="loading">
    <h2>
      {{ $t('title.appselections') }}
      <el-switch
        v-model="showMySelections"
        style="margin-left: 20px;"
        active-text="Mes sélections">
      </el-switch>
      <el-button v-if="$store.state.user.is_staff" size="mini" icon="el-icon-plus" circle class="addselection" @click="showCreateSelection()"></el-button>
    </h2>

    <el-dialog
      title="Création d'une sélection"
      :visible.sync="showCreate"
      width="30%">
      <div>
          <label class="form-item">{{ $t("g.name") }}</label>
          <el-row>
            <el-input v-model="newName" type='text'/>
          </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showCreate = false">Annuler</el-button>
        <el-button type="primary" :disabled="!canAddSelection" @click="createSelection()">Ajouter la sélection</el-button>
      </span>
    </el-dialog>

    <div class="block">
      <el-pagination
        layout="sizes, prev, pager, next"
        :total="selections.count"
        :page-sizes="[10, 25, 50, 100]"
        :page-size="25"
        @size-change="(size) => { this.limit = size; loadPage(this.page) }"
        @current-change="loadPage">
      </el-pagination>
    </div>

    <el-table
        :data="selections.results"
        width="100%"
        v-loading="tableLoading">
      <el-table-column prop="id" width="64">
        <template slot-scope="scope">
          <router-link class="myClass" :to="$route.path + `${scope.row.id}`">
            <el-button type="primary" icon="el-icon-edit" circle/>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column
        prop="created_by.name"
        :label="$t('g.created_by')"
        renderType="input"
        />
      <el-table-column prop="translated_fields.name" :label="$t('g.name')" :formatter="trf"></el-table-column>
      <el-table-column-pro
        prop="typology"
        label="Typology"
        renderType="select"
        :param.sync="filterParams.typology"
        :selectList="tableFilterTopology"
        :callback="onTableFilterChange"/>
      <el-table-column prop="created_at" :label="$t('g.created_at')"></el-table-column>
      <el-table-column prop="id" :label="$t('user.permissions')">
        <template slot-scope="scope">
          <el-tag type="info" v-if="scope.row.is_public">Public</el-tag>
        </template>
      </el-table-column>
    </el-table>
  </el-row>
</template>

<script>

export default {
  name: 'AppSelections',
  props: ['appID'],

  data () {
    return {
      loading: false,
      tableLoading: false,
      limit: 25,
      offset: 0,
      showCreate: false,
      newName: '',
      filterParams: {
          typology: null,
          created_by: null
      },
      tableFilterTopology: [{
        text: 'temporary',
        value: 'temporary'
      }, {
        text: 'favorites',
        value: 'favorites'
      }, {
        text: 'albums',
        value: 'albums'
      }],
      selections: {}
    }
  },

  computed: {
    canAddSelection () {
      if (this.newName.length < 3) return
      return true
    },
    showMySelections: {
      get: function () {
        return this.filterParams.created_by !== null
      },
      set: function (value) {
        if (value) {
          this.filterParams.created_by = this.$store.state.user.user_id
        } else {
          this.filterParams.created_by = null
        }
        this.loadPage(0)
      }
    }
  },

  methods: {
    onTableFilterChange () {
        this.loadPage(0)
    },
    showCreateSelection () {
      this.newName = ''
      this.showCreate = true
    },
    createSelection () {
      let payload = {
        poi_list: [],
        translated_fields: {
            name: {
                fr: this.newName,
                en: ''
            },
            description: {
                fr: '',
                en: ''
            }
        },
        typology: 'temporary',
        is_public: false
      }
      this.$store.dispatch('createSelection', payload).then(selection => {
        this.$router.push({path: this.$route.path + `${selection.id}`})
        this.showCreate = false
      }).catch(error => {
        this.$notify({
          title: 'Echec création',
          message: `Erreur de création de la sélection (${error})`,
          type: 'error'
        })
      })
    },

    async loadPage (page) {
      this.tableLoading = true
      this.offset = this.limit * (page - 1)
      let params = {
        offset: this.offset,
        limit: this.limit
      }
      let mergedParams = Object.assign({}, params, this.filterParams)
      this.selections = await this.$store.dispatch('loadSelections', mergedParams)
      this.tableLoading = false
    }
  },

  async created () {
    await this.$store.dispatch('ensureApp', {
      appID: this.appID
    })
    await this.loadPage(0)
    this.loading = false
  }
}
</script>

<style scoped>
.addselection {
  float: right;
  margin-left: 10px;
}
</style>
<style>
.el-dialog__body {
    padding-top: 0px;
    padding-bottom: 0px;
}
</style>
