<template>
  <div class="">
    <h2>{{ $t('title.applayouts') }}</h2>

    <el-alert
      title="Fonctionnalité en cours de développement"
      type="warning"
      description="La gestion des layouts est en cours de développement, néanmoins nécessaire à l'exploitation de l'application mobile, export pdf, etc."
      show-icon
      :closable="false"
      />

    <el-table :data="layouts" width="100%" v-loading="loading">
      <el-table-column prop="id" width="64">
        <template slot-scope="scope">
          <router-link class="myClass" :to="$route.path + `${scope.row.id}`">
            <el-button type="primary" icon="el-icon-edit" circle/>
          </router-link>
        </template>
      </el-table-column>
      <el-table-column prop="is_selected" label="Actif" sortable  width="76">
        <template slot-scope="scope">
          <i v-if="scope.row.is_selected" class="el-icon-check"/>
        </template>
      </el-table-column>
      <el-table-column prop="layout_fields.name" label="Nom" sortable></el-table-column>
      <el-table-column prop="id" width="240" align="right">
        <template slot-scope="scope">
          <el-button v-if="!scope.row.is_selected" @click="useLayout(scope.row.id)">Utiliser</el-button>
          <el-button @click="duplicateLayout(scope.row.id)">Dupliquer</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
export default {
  name: 'AppLayouts',
  props: ['appID'],

  data () {
    return {
      loading: true
    }
  },

  methods: {
    duplicateLayout (layoutID) {
      let layout = null
      for (let entry of this.$store.state.layouts) {
        if (entry.id === layoutID) {
          layout = cloneDeep(entry)
          break
        }
      }
      if (layout === null) return

      this.$prompt('Nom du nouveau layout', 'Créer un layout', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        inputPattern: /^[\w]+$/,
        inputErrorMessage: 'Nom de layout invalide'
      }).then(value => {
        let payload = {
          is_selected: false,
          displayed_fields: layout.displayed_fields,
          layout_fields: layout.layout_fields
        }
        payload.layout_fields.name = value.value
        this.$store.dispatch('createLayout', payload)
      })
    },
    useLayout (layoutID) {
      let payload = { is_selected: true }
      this.loading = true
      this.$store.dispatch('updateLayout', {
        layoutID: layoutID,
        payload: payload
      })
      .then(() => this.refreshList())
      .catch(this.refreshList)
    },
    async refreshList () {
      await this.$store.dispatch('loadLayouts', {
        appID: this.appID
      })
      this.loading = false
    }
  },

  computed: {
    layouts () {
      return this.$store.state.layouts
    }
  },

  async created () {
    await this.$store.dispatch('ensureApp', {
      appID: this.appID
    })
    await this.$store.dispatch('loadLayouts', {
      appID: this.appID
    })
    this.loading = false
  }
}
</script>

<style scoped>
</style>
