<template>
  <div class="transfer">
    <el-transfer v-model="tValue" :data="tData"
      :titles="['Champs disponibles', 'Champs utilisés']"
      target-order="push"
      :format="{ noChecked: ' ', hasChecked: ' ' }"
      @change="change">
    </el-transfer>
  </div>
</template>

<script>
import Vue from 'vue'
export default {
  name: 'AppLayoutDisplayedFields',
  props: {
    value: Object,
    fieldCategory: String
  },
  data () {
    return {
      fieldMap: {},
      tValue: [],
      tData: []
    }
  },
  methods: {
    change () {
      let payload = {}
      for (let [index, fieldName] of Object.entries(this.tValue)) {
        payload[index] = this.fieldMap[fieldName]
      }
      this.$emit('change', payload)
    }
  },
  mounted () {
    let fields = this.$store.state.app.fields[this.fieldCategory]
    let data = []
    for (let field of fields) {
      let entry = {
        key: field.field_name,
        label: `${field.displayed_name.fr} (${field.field_name} [${field.type}])`,
        disabled: false
      }
      this.fieldMap[field.field_name] = field
      data.push(entry)
    }
    Vue.set(this, 'tData', data)

    let usedFields = []
    for (let field of Object.values(this.value)) {
      usedFields.push(field.field_name)
    }
    Vue.set(this, 'tValue', usedFields)
  }
}
</script>

<style>
div.transfer {
  margin: 10px;
}
.el-transfer-panel {
  width: 40%;
}
</style>
