<template>
  <el-row
    type="flex"
    justify="center"
    id="content-wrapper"
    :class="embed ? 'embed' : ''"
  >
    <el-col :md="20" :lg="16" :xl="10" id="content">
      <h2>
        <el-tag type="info" size="mini">{{ currentPoi.id }}</el-tag>
        {{ $t("title.apppoi") }}: {{ poiTitle }}
      </h2>

      <el-row v-loading="loading">
        <el-tabs :type="embed ? '' : 'border-card'">
          <el-tab-pane :label="$t('g.section_general')">
            <label class="form-item">{{ $t("g.poi_state") }}</label>
            <el-row>
              <el-col :span="24">
                <el-select
                  v-model="currentPoi.state"
                  @change="updateField('state', $event)"
                >
                  <el-option value="published" label="Publié" />
                  <el-option value="unpublished" label="Non-publié" />
                  <el-option
                    value="wait_validation"
                    label="En attente de validation"
                  />
                </el-select>
              </el-col>
            </el-row>

            <label class="form-item">{{ $t("g.icon_url") }}</label>
            <DropZone
              :value="currentPoi.icon_url"
              :action="`${$config.baseUrl}/upload/`"
              :data="uploadIconData"
              dropmsg="Charger une icône"
              @metadata="updateIconFromMetadata($event)"
              @change="updateField('icon_url', $event)"
            />

            <el-row>
              <el-col :span="8">
                <el-card
                  :body-style="{ padding: '0px' }"
                  v-if="currentPoi.icon_url"
                >
                  <img
                    :src="currentPoi.icon_url | asset(256, 256)"
                    class="image"
                  />
                  <div class="el-card-dimensions">
                    Taille:
                    <span v-if="currentPoi.icon_metadata.original_width">
                      {{ currentPoi.icon_metadata.original_width }}x{{
                        currentPoi.icon_metadata.original_height
                      }}
                    </span>
                    <span v-else>Inconnu</span>
                  </div>
                </el-card>
              </el-col>
            </el-row>

            <label class="form-item">{{ $t("g.icon_selected_url") }}</label>

            <DropZone
              :value="currentPoi.icon_selected_url"
              :action="`${$config.baseUrl}/upload/`"
              :data="uploadIconData"
              dropmsg="Charger une icône quand sélectionné"
              @metadata="updateIconFromMetadata($event, 'selected_')"
              @change="updateField('icon_selected_url', $event)"
            />

            <el-row>
              <el-col :span="8">
                <el-card
                  :body-style="{ padding: '0px' }"
                  v-if="currentPoi.icon_selected_url"
                >
                  <img
                    :src="currentPoi.icon_selected_url | asset(256, 256)"
                    class="image"
                  />
                  <div class="el-card-dimensions">
                    Taille:
                    <span
                      v-if="currentPoi.icon_metadata.selected_original_width"
                    >
                      {{ currentPoi.icon_metadata.selected_original_width }}x{{
                        currentPoi.icon_metadata.selected_original_height
                      }}
                    </span>
                    <span v-else>Inconnu</span>
                  </div>
                </el-card>
              </el-col>
            </el-row>

            <label class="form-item">{{ $t("g.icon_anchor") }}</label>
            <el-row>
              <el-col :span="8">
                <el-select
                  v-model="currentPoi.icon_metadata.anchor"
                  @change="updateIconMetadata('anchor', $event)"
                >
                  <el-option value="" label="(non précisée)" />
                  <el-option value="center" label="Centre" />
                  <el-option value="bottom" label="Bas" />
                  <el-option value="top" label="Haut" />
                  <el-option value="left" label="Gauche" />
                  <el-option value="right" label="Droite" />
                  <el-option value="top-left" label="Haut gauche" />
                  <el-option value="top-right" label="Haut droit" />
                  <el-option value="bottom-left" label="Bas gauche" />
                  <el-option value="bottom-right" label="Bas droite" />
                </el-select>
              </el-col>
            </el-row>

            <label class="form-item">{{ $t("g.icon_dimensions") }}</label>
            <el-row>
              <el-col :span="8">
                <el-checkbox
                  :value="currentPoi.icon_metadata.use_custom"
                  @change="updateIconMetadata('use_custom', $event)"
                  >{{ $t("g.icon_dimensions_custom") }}</el-checkbox
                >
              </el-col>
            </el-row>
            <el-row v-if="!currentPoi.icon_metadata.use_custom">
              <el-col :sm="6">
                <label class="form-item">{{
                  $t("g.icon_custom_box_size")
                }}</label>
              </el-col>
              <el-col :sm="18">
                <el-input-number
                  :value="currentPoi.icon_metadata.box_size"
                  @change="updateIconMetadata('box_size', $event)"
                  :min="16"
                  type="text"
                >
                </el-input-number>
              </el-col>
            </el-row>
            <el-row v-if="currentPoi.icon_metadata.use_custom">
              <el-col :sm="6">
                <label class="form-item">{{
                  $t("g.icon_custom_size_width")
                }}</label>
              </el-col>
              <el-col :sm="18">
                <el-input-number
                  :value="currentPoi.icon_metadata.width"
                  @change="updateIconMetadataSize('width', $event)"
                  type="text"
                >
                </el-input-number>
              </el-col>
              <el-col :sm="6">
                <label class="form-item">{{
                  $t("g.icon_custom_size_height")
                }}</label>
              </el-col>
              <el-col :sm="18">
                <el-input-number
                  :value="currentPoi.icon_metadata.height"
                  @change="updateIconMetadataSize('height', $event)"
                  type="text"
                >
                </el-input-number>
              </el-col>
            </el-row>

            <label class="form-item">{{ $t("g.categories") }}</label>
            <el-tree
              :data="$store.state.categories"
              show-checkbox
              node-key="id"
              default-expand-all
              ref="poiCategories"
              @check="updateCategories($event)"
              :expand-on-click-node="false"
            >
              <span class="custom-tree-node" slot-scope="{ node, data }">
                <span>
                  <img
                    v-if="data.icon_url"
                    :src="data.icon_url | asset(64, 64)"
                    class="icon-image"
                  />
                  {{ tf(data, "name") || "(empty field)" }}
                </span>
                <span>
                  <el-tag type="info" size="mini">ID {{ data.id }}</el-tag>
                </span>
              </span>
            </el-tree>
          </el-tab-pane>

          <el-tab-pane :label="$t('g.section_fields')">
            <div
              v-for="field in poi_default_translated_fields"
              v-bind:key="field.id"
            >
              <label class="el-form-item__label">{{
                tr(field.displayed_name)
              }}</label>
              <TranslatedField
                v-if="currentPoi.translated_fields"
                v-model="currentPoi.translated_fields[field.field_name]"
                :fieldType="field.type"
                @change="updateTranslatedField(field.field_name, $event)"
              />
            </div>

            <template v-if="currentAppState">
              <div
                v-for="field in poi_default_content_fields"
                v-bind:key="field.id"
              >
                <label class="form-item">{{ tr(field.displayed_name) }}</label>
                <ContentField
                  v-model="currentPoi.content_fields[field.field_name]"
                  :fieldDef="field"
                  @change="updateContentField(field.field_name, $event)"
                />
              </div>
            </template>
          </el-tab-pane>

          <el-tab-pane
            :label="$t('g.section_geojson_style')"
            v-if="!loading && isGeojson"
          >
            <label class="form-item">Couleur du remplissage</label>
            <el-row>
              <el-color-picker
                :value="currentProperties.fillColor"
                color-format="hex"
                @change="updateProperties('fillColor', $event)"
                @active-change="updateProperties('fillColor', $event)"
              ></el-color-picker>
            </el-row>

            <label class="form-item">Opacité du remplissage</label>
            <el-row>
              <el-slider
                v-model="currentProperties.fillOpacity"
                @change="updateProperties('fillOpacity', $event)"
                :min="0"
                :max="1"
                :step="0.01"
                show-input
              >
              </el-slider>
            </el-row>

            <label class="form-item">Couleur du contour</label>
            <el-row>
              <el-color-picker
                :value="currentProperties.color"
                color-format="hex"
                @change="updateProperties('color', $event)"
                @active-change="updateProperties('color', $event)"
              ></el-color-picker>
            </el-row>

            <label class="form-item">Opacité du contour</label>
            <el-row>
              <el-slider
                v-model="currentProperties.opacity"
                @change="updateProperties('opacity', $event)"
                :min="0"
                :max="1"
                :step="0.01"
                show-input
              >
              </el-slider>
            </el-row>

            <label class="form-item">Epaisseur du contour</label>
            <el-row>
              <el-slider
                v-model="currentProperties.width"
                @change="updateProperties('width', $event)"
                :min="0"
                :max="10"
                :step="0.1"
                show-input
              >
              </el-slider>
            </el-row>
          </el-tab-pane>

          <el-tab-pane label="Médias">
            <label class="form-item">Ajouter un média</label>
            <DropZone
              :action="`${$config.baseUrl}/upload/`"
              :data="uploadMediaData"
              dropmsg="Charger un média"
              @change="onAddMediaUrl($event)"
            />

            <label class="form-item">Liste des médias</label>
            <div class="media-list">
              <div
                v-for="(media, index) in currentPoi.media"
                class="media"
                :key="index"
              >
                <div class="media-entry-type">
                  <span v-if="media.video_url">Vidéo</span>
                  <span v-else-if="media.sounds">Son</span>
                  <span v-else-if="media.image_url">Image</span>
                  <span v-else>Non reconnu</span>
                  <el-button
                    v-if="index > 0"
                    @click="moveMedia(index, index - 1)"
                    type="primary"
                    class="el-icon-arrow-up right"
                    circle
                  ></el-button>
                  <el-button
                    v-if="index < currentPoi.media.length - 1"
                    @click="moveMedia(index, index + 1)"
                    type="primary"
                    class="el-icon-arrow-down right"
                    circle
                  ></el-button>
                  <el-button
                    type="danger"
                    @click="deleteMedia(index)"
                    class="el-icon-delete right"
                    circle
                  ></el-button>
                </div>

                <img
                  v-if="media.image_url"
                  :src="media.image_url | asset(256, 256)"
                  class="image"
                />

                <template v-if="media.video_url">
                  <el-row>
                    <el-col :span="24">
                      <label class="form-item">Séparer par langue</label>
                      <el-switch
                        :value="media.multilang"
                        @change="updateMediaMultilang(index, $event)"
                      />
                    </el-col>
                  </el-row>

                  <video
                    v-if="media.multilang !== true"
                    :src="media.video_url"
                    style="width: 100%"
                    controls
                  />
                  <template v-else>
                    <div
                      v-for="lang in $store.state.languages"
                      v-bind:key="lang"
                      class="sound"
                    >
                      <div class="sound-header">
                        <flag :iso="lang" />
                        <span class="sound-title"
                          >Vidéo {{ $config.languages[lang] }}</span
                        >
                        <el-button
                          v-if="media.videos && media.videos[lang]"
                          size="mini"
                          type="danger"
                          @click="deleteVideoMediaIndex(index, lang)"
                          class="el-icon-delete right"
                          circle
                        ></el-button>
                      </div>
                      <template v-if="media.videos && media.videos[lang]">
                        <video
                          :src="media.videos[lang].url"
                          style="width: 100%"
                          controls
                        />
                      </template>
                      <template v-else>
                        <DropZone
                          :action="`${$config.baseUrl}/upload/`"
                          :data="uploadMediaData"
                          dropmsg="Charger une vidéo"
                          @change="onUpdateVideoMediaIndex(index, lang, $event)"
                        />
                      </template>
                    </div>
                  </template>
                </template>

                <template v-if="media.sounds">
                  <div
                    v-for="lang in $store.state.languages"
                    v-bind:key="lang"
                    class="sound"
                  >
                    <div class="sound-header">
                      <flag :iso="lang" />
                      <span class="sound-title"
                        >Audio {{ $config.languages[lang] }}</span
                      >
                      <el-button
                        v-if="media.sounds[lang]"
                        size="mini"
                        type="danger"
                        @click="deleteSoundMediaIndex(index, lang)"
                        class="el-icon-delete right"
                        circle
                      ></el-button>
                    </div>

                    <template v-if="media.sounds[lang]">
                      <audio controls>
                        <source :src="media.sounds[lang].url" />
                      </audio>
                    </template>
                    <template v-else>
                      <DropZone
                        :action="`${$config.baseUrl}/upload/`"
                        :data="uploadMediaData"
                        dropmsg="Charger un son"
                        @change="onUpdateSoundMediaIndex(index, lang, $event)"
                      />
                    </template>
                  </div>
                </template>

                <label class="form-item">Légende</label>
                <TranslatedField
                  :value="media.legend || {}"
                  :fieldType="'longstring'"
                  @change="updateMediaLegend(index, $event)"
                />

                <div class="el-row">
                  <label class="form-item el-col el-col-6">Layout</label>
                  <LayoutField
                    class="el-col el-col-18"
                    :value="media.layout_id"
                    @change="updateMediaLayout(index, $event)"
                  />
                </div>

                <div class="el-row">
                  <label class="form-item el-col el-col-6">Nom</label>
                  <ContentField
                    class="el-col el-col-18"
                    style="margin-bottom: -20px"
                    :value="media.name"
                    :fieldDef="{ type: 'string' }"
                    placeholder="Exemple: pont-gare"
                    @change="updateMediaName(index, $event)"
                  />
                </div>

                <PlatformField
                  :value="media.platform"
                  @change="updateMediaPlatform(index, $event)"
                />
              </div>
            </div>
          </el-tab-pane>

          <el-tab-pane :label="$t('g.section_admin')">
            <label class="form-item">{{ $t("g.poi_map_linked") }}</label>
            <el-row>
              <el-col :span="24">
                <el-select v-model="currentPoi.map" @change="updateMap($event)">
                  <el-option
                    v-for="map in maps"
                    :key="map.id"
                    :value="map.id"
                    :label="map.name"
                  />
                </el-select>
              </el-col>
            </el-row>

            <label class="form-item">{{ $t("g.delete") }}</label>
            <el-row>
              <el-col :span="8">
                <el-button type="danger" @click="deletePoi()"
                  >Supprimer le POI</el-button
                >
              </el-col>
            </el-row>
          </el-tab-pane>

          <el-tab-pane
            v-if="this.$store.state.user.is_staff"
            :label="$t('g.section_debug')"
          >
            <div v-if="currentPoi.created_at">
              Création le {{ currentPoi.created_at }} par
              {{ currentPoi.created_by.name }}
              <br />
              Dernière mise à jour le {{ currentPoi.updated_at }} par
              {{ currentPoi.updated_by.name }}
            </div>
            <h2>Current poi</h2>
            <pre>{{ currentPoi }}</pre>
            <h2>Current app</h2>
            <pre>{{ currentAppState }}</pre>
          </el-tab-pane>
        </el-tabs>
      </el-row>
    </el-col>
  </el-row>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import Vue from "vue";
import TranslatedField from "./TranslatedField";
import ContentField from "./ContentField";
import LayoutField from "./LayoutField";
import PlatformField from "./PlatformField";
import DropZone from "./DropZone";
import { defaultValue, ensureNumber } from "../utils";
import Flag from "./Flag";

export default {
  name: "AppPoi",
  components: {
    TranslatedField,
    ContentField,
    LayoutField,
    PlatformField,
    DropZone,
    Flag
  },
  props: {
    embed: {
      type: Boolean,
      default: false
    },
    appID: {
      type: Number
    },
    mapID: {
      type: Number
    },
    poiID: {
      type: Number
    }
  },

  data() {
    return {
      loading: true,
      poi_default_translated_fields: [],
      poi_default_content_fields: [],
      currentPoi: {
        icon_metadata: {}
      },
      currentProperties: {}
    };
  },

  methods: {
    async commit(field, value) {
      await this.$store.dispatch("updateCurrentPoi", {
        [field]: value
      });
    },

    async updateField(field, value) {
      Vue.set(this.currentPoi, field, value);
      this.commit(field, value);
    },

    async updateMap(value) {
      Vue.set(this.currentPoi, "map", value);
      await this.$store.dispatch("updateCurrentPoiMap", { map_id: value });
      this.$message({
        message: "Le POI a bien été déplacé",
        type: "success"
      });
    },

    rgb2hex(value) {
      let result = value.match(/rgb\((\d+), (\d+), (\d+)\)/);
      if (result !== null) {
        let r = parseInt(result[1]).toString(16);
        let g = parseInt(result[2]).toString(16);
        let b = parseInt(result[3]).toString(16);
        if (r.length % 2) r = "0" + r;
        if (g.length % 2) g = "0" + g;
        if (b.length % 2) b = "0" + b;
        return "#" + r + g + b;
      }
      return result;
    },

    async updateProperties(field, value) {
      let properties = this.currentPoi.properties;
      if (field === "fillColor") properties["fill"] = this.rgb2hex(value);
      else if (field === "fillOpacity") properties["fill-opacity"] = value;
      else if (field === "color") properties["stroke"] = this.rgb2hex(value);
      else if (field === "width") properties["stroke-width"] = value;
      else if (field === "opacity") properties["opacity"] = value;
      else properties[field] = value;
      Vue.set(this.currentProperties, field, value);
      Vue.set(this.currentPoi, "properties", properties);
      await this.commit("properties", properties);
    },

    async updateIconFromMetadata(el, prefix) {
      var payload = this.currentPoi.icon_metadata;
      payload[(prefix || "") + "original_width"] = el.width;
      payload[(prefix || "") + "original_height"] = el.height;
      Vue.set(this.currentPoi, "icon_metadata", payload);
      this.commit("icon_metadata", payload);
    },

    moveMedia(indexFrom, indexTo) {
      let payload = cloneDeep(this.currentPoi.media);
      let tmp = payload[indexTo];
      payload[indexTo] = payload[indexFrom];
      payload[indexFrom] = tmp;
      this.updateField("media", payload);
    },

    async deleteMedia(ev) {
      this.$confirm("Voulez-vous supprimer ce média?", "Suppression du média", {
        confirmButtonText: "Oui je supprime ce média",
        cancelButtonText: "Non",
        type: "warning"
      })
        .then(() => {
          let payload = cloneDeep(this.currentPoi.media);
          payload.splice(ev, 1);
          this.updateField("media", payload);
        })
        .catch(() => {});
    },

    async updateMediaLegend(index, ev) {
      let payload = cloneDeep(this.currentPoi.media);
      payload[index].legend = ev;
      this.updateField("media", payload);
    },

    async updateMediaMultilang(index, ev) {
      let payload = cloneDeep(this.currentPoi.media);
      payload[index].multilang = ev;
      if (payload[index].videos === undefined) {
        payload[index].videos = {};
      }
      this.updateField("media", payload);
    },

    async updateMediaLayout(index, ev) {
      let payload = cloneDeep(this.currentPoi.media);
      payload[index].layout_id = ev;
      this.updateField("media", payload);
    },

    async updateMediaPlatform(index, ev) {
      let payload = cloneDeep(this.currentPoi.media);
      payload[index].platform = ev;
      this.updateField("media", payload);
    },

    async updateMediaName(index, ev) {
      let payload = cloneDeep(this.currentPoi.media);
      payload[index].name = ev;
      this.updateField("media", payload);
    },

    onAddMediaUrl(ev) {
      let medias = this.currentPoi.media || [];
      if (ev.endsWith(".png") || ev.endsWith(".jpg") || ev.endsWith(".jpeg")) {
        medias.push({
          image_url: ev
        });
      } else if (
        ev.endsWith(".mp4") ||
        ev.endsWith(".avi") ||
        ev.endsWith(".webm") ||
        ev.endsWith(".m4v")
      ) {
        const lang = this.$store.state.languages[0];
        const videos = {};
        videos[lang] = {
          url: ev,
          fmt: ev.split(".").pop()
        };
        medias.push({
          video_url: ev,
          multilang: false,
          videos
        });
      } else if (
        ev.endsWith(".m4a") ||
        ev.endsWith(".mp3") ||
        ev.endsWith(".wav")
      ) {
        const lang = this.$store.state.languages[0];
        const sounds = {};
        sounds[lang] = {
          url: ev,
          fmt: ev.substr(-3)
        };
        medias.push({ sounds });
      }
      this.updateField("media", medias);
    },

    onUpdateSoundMediaIndex(index, lang, ev) {
      let medias = this.currentPoi.media || [];
      const media = medias[index];
      if (ev.endsWith(".m4a") || ev.endsWith(".mp3") || ev.endsWith(".wav")) {
        media.sounds[lang] = {
          url: ev,
          fmt: ev.substr(-3)
        };
      }
      this.updateField("media", medias);
    },

    deleteSoundMediaIndex(index, lang) {
      let medias = this.currentPoi.media || [];
      const media = medias[index];
      delete media.sounds[lang];
      this.updateField("media", medias);
    },

    onUpdateVideoMediaIndex(index, lang, ev) {
      let medias = this.currentPoi.media || [];
      const media = medias[index];
      if (
        ev.endsWith(".mp4") ||
        ev.endsWith(".avi") ||
        ev.endsWith(".webm") ||
        ev.endsWith(".m4v")
      ) {
        media.videos[lang] = {
          url: ev,
          fmt: ev.substr(-3)
        };
      }
      this.updateField("media", medias);
    },

    deleteVideoMediaIndex(index, lang) {
      let medias = this.currentPoi.media || [];
      const media = medias[index];
      delete media.videos[lang];
      this.updateField("media", medias);
    },

    updateCategories(ev) {
      if (this.loading) return;
      let keys = this.$refs.poiCategories.getCheckedKeys();
      this.updateField("categories", keys);
    },

    updateIconMetadataSize(field, value) {
      let payload = this.currentPoi.icon_metadata;
      let oWidth = payload.original_width || 48;
      let oHeight = payload.original_height || 48;
      let ratio = oWidth / oHeight;

      if (field === "width") {
        payload["width"] = value;
        payload["height"] = Math.round(value / ratio);
      } else if (field === "height") {
        payload["width"] = Math.round(value * ratio);
        payload["height"] = value;
      }

      Vue.set(this.currentPoi, "icon_metadata", payload);
      this.commit("icon_metadata", payload);
    },

    updateIconMetadata(field, value) {
      let payload = this.currentPoi.icon_metadata;
      payload[field] = value;
      Vue.set(this.currentPoi, "icon_metadata", payload);
      this.commit("icon_metadata", payload);
    },

    updateContentField(field, value) {
      let payload = this.currentPoi.content_fields;
      payload[field] = value;
      Vue.set(this.currentPoi, "content_fields", payload);
      this.commit("content_fields", payload);
    },

    updateTranslatedField(field, value, lang) {
      let payload = this.currentPoi.translated_fields;
      if (typeof payload === "undefined") {
        payload = {};
      }

      if (lang === undefined) {
        payload[field] = value;
      } else {
        let payloadField = payload[field];
        if (typeof payloadField === "undefined") {
          payload[field] = {
            fr: "",
            en: ""
          };
          payloadField = payload[field];
        }
        payloadField[lang] = value;
      }

      Vue.set(this.currentPoi, "translated_fields", payload);
      this.commit("translated_fields", payload);
    },

    deletePoi() {
      let payload = {
        appID: this.$route.params.appID,
        mapID: this.$route.params.mapID,
        poiID: this.$route.params.poiID
      };
      this.$confirm(
        "Vous allez supprimer le POI de manière définitive. Continuez?",
        "Attention !",
        {
          confirmButtonText: "Oui, je supprime!",
          cancelButtonText: "Annuler",
          type: "warning"
        }
      ).then(() => {
        this.$store.dispatch("deletePoi", payload);
        this.$router.replace({
          name: "applivemap",
          params: this.$route.params
        });
      });
    },

    async prepare() {
      this.loading = true;
      await this.$store.dispatch("ensureApp", {
        appID: this.appID
      });
      await this.$store.dispatch("ensureMaps", {
        appID: this.appID
      });
      await this.$store.dispatch("ensureMap", {
        appID: this.appID,
        mapID: this.mapID
      });
      await this.$store.dispatch("ensureCategories", {
        appID: this.appID,
        mapID: this.mapID
      });
      await this.$store.dispatch("loadPoi", {
        appID: this.appID,
        mapID: this.mapID,
        poiID: this.poiID
      });
      let currentPoi = cloneDeep(this.$store.state.currentPoi);
      // fill the poi with all the translated_fields and default_fields
      for (let field of this.$store.state.app.fields
        .poi_default_translated_fields) {
        if (currentPoi.translated_fields[field.field_name] === undefined) {
          currentPoi.translated_fields[field.field_name] = {};
        }
      }

      // ensure defaults for metadata
      let metadata = currentPoi.icon_metadata;
      if (!Object.prototype.hasOwnProperty.call(metadata, "use_custom")) {
        metadata["use_custom"] = false;
        metadata["width"] = 48;
        metadata["height"] = 48;
        metadata["box_size"] = 48;
        metadata["anchor"] = "bottom";
        currentPoi.icon_metadata = metadata;
      }

      let properties = currentPoi.properties;
      this.currentProperties = {
        fillOpacity: defaultValue(
          ensureNumber(properties["fill-opacity"]),
          0.2
        ),
        fillColor: defaultValue(properties["fill"], "#3388ff"),
        color: defaultValue(properties["stroke"], "#3388ff"),
        width: defaultValue(ensureNumber(properties["stroke-width"]), 3),
        opacity: defaultValue(ensureNumber(properties["opacity"]), 1.0)
      };

      this.currentPoi = currentPoi;
      this.$refs.poiCategories.setCheckedKeys(currentPoi.categories);

      this.poi_default_content_fields = this.$store.state.app.fields.poi_default_content_fields;
      this.poi_default_translated_fields = this.$store.state.app.fields.poi_default_translated_fields;
      this.loading = false;
    }
  },

  computed: {
    languages() {
      return this.$store.state.languages;
    },
    uploadIconData() {
      return {
        application_id: this.$route.params.appID,
        map_id: this.$route.params.mapID,
        poi_id: this.$route.params.poiID,
        intent: "poi-icon"
      };
    },
    uploadMediaData() {
      return {
        application_id: this.$route.params.appID,
        map_id: this.$route.params.mapID,
        poi_id: this.$route.params.poiID,
        intent: "poi-media"
      };
    },
    currentAppState() {
      return this.$store.state.app;
    },
    currentPoiState() {
      return this.$store.state.currentPoi;
    },
    maps() {
      return this.$store.state.maps;
    },
    poiTitle() {
      return this.$store.getters.getPoiTitle;
    },
    isGeojson() {
      let position = this.currentPoi.outdoor_position;
      if (this.currentPoi.indoor_position !== undefined) {
        position = this.currentPoi.indoor_position;
      }
      return position.type === "Polygon";
    }
  },

  watch: {
    $route(from, to) {
      this.prepare();
    }
  },

  mounted() {
    this.prepare();
  }
};
</script>

<style scoped>
#content-wrapper.embed h2 {
  font-size: 16px;
  display: flex;
}

#content-wrapper.embed h2 span.el-tag {
  margin-right: 5px;
}

#content-wrapper.embed #content {
  width: 100%;
}

.image {
  width: 100%;
  display: block;
}

.el-card-dimensions {
  font-size: 10px;
  color: gray;
  padding: 3px;
}

.media-list {
  padding-left: 0;
  min-height: 20px;
  margin-top: 0;
}

.media-list:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.media-entry-type {
  background: #eee;
  margin: -12px -15px;
  margin-bottom: 15px;
  padding: 0px 10px;
  font-size: 14px;
  height: 52px;
  display: flex;
  align-items: center;
}

.media-entry-type > span {
  flex: 1;
  padding: ;
}

.media {
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid #ddd;
}

.media-list .image {
  width: auto;
}

.sound {
  margin-bottom: 10px;
}

.sound-header {
  height: 32px;
  display: flex;
  align-items: center;
  flex-direction: row;
  font-size: 14px;
}

.sound-header .flag-icon {
  margin-right: 10px;
}

.sound-header .sound-title {
  flex: 1;
}

.right {
  float: right;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.icon-image {
  width: 24px;
  vertical-align: middle;
}
</style>
