<template>
  <el-row type="flex" justify="center">
    <el-col :md="20" :lg="16" :xl="10">
    <h2>{{ $t('title.applayout') }}: {{ currentLayout.layout_fields.name }}</h2>
    <el-row v-loading="loading">
        <el-tabs type="border-card">

          <el-tab-pane label="General">

            <label class="form-item">{{ $t("g.name") }}</label>
            <el-row>
              <el-input
                v-model="currentLayout.layout_fields.name"
                @change="updateLayoutField('name', $event)"
                type='text'>
              </el-input>
            </el-row>

          </el-tab-pane>

          <el-tab-pane label="Champs">

            <div class="tab-section">
              <span>{{ $t("g.map_default_content_fields") }}</span>
            </div>
            <div class="tab-section-content">
              <AppLayoutDisplayedFields
                v-if="!loading"
                v-model="currentLayout.displayed_fields.map_default_content_fields"
                fieldCategory="map_default_content_fields"
                @change="updateDisplayedField('map_default_content_fields', $event)"
                />
            </div>

            <div class="tab-section">
              <span>{{ $t("g.map_default_translated_fields") }}</span>
            </div>
            <div class="tab-section-content">
              <AppLayoutDisplayedFields
                v-if="!loading"
                v-model="currentLayout.displayed_fields.map_default_translated_fields"
                fieldCategory="map_default_translated_fields"
                @change="updateDisplayedField('map_default_translated_fields', $event)"
                />
            </div>

            <div class="tab-section">
              <span>{{ $t("g.poi_default_content_fields") }}</span>
            </div>
            <div class="tab-section-content">
              <AppLayoutDisplayedFields
                v-if="!loading"
                v-model="currentLayout.displayed_fields.poi_default_content_fields"
                fieldCategory="poi_default_content_fields"
                @change="updateDisplayedField('poi_default_content_fields', $event)"
                />
            </div>

            <div class="tab-section">
              <span>{{ $t("g.poi_default_translated_fields") }}</span>
            </div>
            <div class="tab-section-content">
              <AppLayoutDisplayedFields
                v-if="!loading"
                v-model="currentLayout.displayed_fields.poi_default_translated_fields"
                fieldCategory="poi_default_translated_fields"
                @change="updateDisplayedField('poi_default_translated_fields', $event)"
                />
            </div>

            <div class="tab-section">
              <span>{{ $t("g.selection_default_content_fields") }}</span>
            </div>
            <div class="tab-section-content">
              <AppLayoutDisplayedFields
                v-if="!loading"
                v-model="currentLayout.displayed_fields.selection_default_content_fields"
                fieldCategory="selection_default_content_fields"
                @change="updateDisplayedField('selection_default_content_fields', $event)"
                />
            </div>

            <div class="tab-section">
              <span>{{ $t("g.selection_default_translated_fields") }}</span>
            </div>
            <div class="tab-section-content">
              <AppLayoutDisplayedFields
                v-if="!loading"
                v-model="currentLayout.displayed_fields.selection_default_translated_fields"
                fieldCategory="selection_default_translated_fields"
                @change="updateDisplayedField('selection_default_translated_fields', $event)"
                />
            </div>

            <div class="tab-section">
              <span>{{ $t("g.category_default_content_fields") }}</span>
            </div>
            <div class="tab-section-content">
              <AppLayoutDisplayedFields
                v-if="!loading"
                v-model="currentLayout.displayed_fields.category_default_content_fields"
                fieldCategory="category_default_content_fields"
                @change="updateDisplayedField('category_default_content_fields', $event)"
                />
            </div>

            <div class="tab-section">
              <span>{{ $t("g.category_default_translated_fields") }}</span>
            </div>
            <div class="tab-section-content">
              <AppLayoutDisplayedFields
                v-if="!loading"
                v-model="currentLayout.displayed_fields.category_default_translated_fields"
                fieldCategory="category_default_translated_fields"
                @change="updateDisplayedField('category_default_translated_fields', $event)"
                />
            </div>

            <div class="tab-section">
              <span>{{ $t("g.user_default_profile_fields") }}</span>
            </div>
            <div class="tab-section-content">
              <AppLayoutDisplayedFields
                v-if="!loading"
                v-model="currentLayout.displayed_fields.user_default_profile_fields"
                fieldCategory="user_default_profile_fields"
                @change="updateDisplayedField('user_default_profile_fields', $event)"
                />
            </div>

          </el-tab-pane>

        <el-tab-pane v-if="this.$store.state.user.is_staff" :label="$t('g.section_debug')">
            <h2>Current layout</h2>
            <pre>{{ currentLayout }}</pre>
          </el-tab-pane>

      </el-tabs>
    </el-row>

  </el-col>
  </el-row>

</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import Vue from 'vue'
import AppLayoutDisplayedFields from './AppLayoutDisplayedFields'

export default {
  name: 'AppLayout',
  props: ['appID', 'layoutID'],
  components: { AppLayoutDisplayedFields },

  data () {
    return {
      loading: true,
      currentLayout: {
        layout_fields: {
          name: ''
        },
        displayed_fields: {
        }
      }
    }
  },

  methods: {

    async commit (field, value) {
      await this.$store.dispatch('updateCurrentLayout', {
        [field]: value
      })
    },

    async updateField (field, value) {
      Vue.set(this.currentLayout, field, value)
      this.commit(field, value)
    },

    async updateDisplayedField (name, value) {
      Vue.set(this.currentLayout.displayed_fields, name, value)
      this.commit('displayed_fields', this.currentLayout.displayed_fields)
    },

    async updateLayoutField (name, value) {
      Vue.set(this.currentLayout.layout_fields, name, value)
      this.commit('layout_fields', this.currentLayout.layout_fields)
    }
  },

  computed: {
    languages () {
      return this.$store.state.languages
    }
  },

  async mounted () {
    await this.$store.dispatch('ensureApp', {
      appID: this.appID
    })
    await this.$store.dispatch('loadLayout', {
      appID: this.appID,
      layoutID: this.layoutID
    })
    this.currentLayout = cloneDeep(this.$store.state.currentLayout)
    this.loading = false
  }
}
</script>

<style scoped>
.image {
  width: 100%;
  display: block;
}
.el-transfer__buttons {
    padding: 0 10px;
}</style>
