<template>
  <el-collapse v-if="value && value.length" accordion>
    <el-collapse-item v-for="(field, index) in value" v-bind:key="field.index">
      <template slot="title">
        <div class="title-container">
          <div>{{ field.field_name }}</div>
        <el-tag v-if="field.style" size="mini">{{ field.style }}</el-tag>
        <el-tag v-if="field.type" size="mini" type="info">{{ field.type }}</el-tag>
        <el-tag v-if="!isProtected(field)" size="mini" type="danger">protected</el-tag>
        </div>
      </template>

      <label class="form-item">{{ $t("g.fieldname") }}</label>
      <el-row>
        <el-input
          v-model="field.field_name"
          :disabled="!isProtected(field)"
          />
      </el-row>

      <label class="form-item">{{ $t("g.fielddisplayedname") }}</label>
      <TranslatedField
        v-model="field.displayed_name"
        @change="field.displayed_name = $event"
        :span="6" :gutter="10"/>

      <label class="form-item">{{ $t("g.fieldtype") }}</label>
      <el-row>
        <el-select
          v-model="field.type"
          :disabled="!isProtected(field)"
          >
          <el-option v-for="tp in fieldTypes" v-bind:key="tp" :value="tp" :label="$i18n.t(`fieldTypes.${tp}`)"/>
        </el-select>
      </el-row>

      <label class="form-item">{{ $t("g.fieldstyle") }}</label>
      <el-row>
        <el-select v-model="field.style">
          <el-option
            v-for="item in $config.fieldStyles"
            :key="item.value"
            :label="item.label"
            :value="item.value"/>
        </el-select>
      </el-row>

      <el-button
        class="deleteField"
        v-if="isProtected(field)"
        type="danger"
        @click="removeFieldAt(index)">{{ $t("g.delete") }}</el-button>

    </el-collapse-item>
  </el-collapse>
</template>

<script>
import TranslatedField from './TranslatedField'

export default {
  name: 'AppSettingsFields',
  props: {
    value: Array,
    fieldCategory: String,
    fieldTypes: Array
  },
  components: { TranslatedField },
  methods: {
    isProtected (field) {
      return this.$config.protectedFields.indexOf(`${this.fieldCategory}.${field.field_name}`) === -1
    },
    removeFieldAt (index) {
      let field = this.value[index]
      this.$confirm(`Confirmez-vous la suppression du champ ${field.field_name}?`, 'Attention', {
        confirmButtonText: 'Oui',
        cancelButtonText: 'Annuler',
        type: 'warning'
      }).then(() => {
        let field = this.value.splice(index, 1)
        this.$notify({
          title: 'Action effectuée',
          message: `La suppression du champ ${field.field_name} a été effectuée`,
          type: 'success'
        })
      })
    }
  }
}
</script>

<style scoped>
button.deleteField {
  float: right;
  margin-top: -10px;
  margin-bottom: 10px;
}
.title-container {
  width: 100%;
  display: flex;
  align-items: center;
  margin-right: 10px;
}
.title-container div {
  flex-grow: 1;
}
.title-container .el-tag {
  margin-left: 10px;
}
</style>
