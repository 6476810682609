
<template>
  <el-row type="flex" justify="center" id="content-wrapper" :class="embed?'embed':''">
    <el-col :md="20" :lg="16" :xl="10" id="content">
    <h2>{{ $t('title.appuser') }}: {{ currentUserName }}</h2>

    <el-row v-loading="loading">
      <el-tabs :type="embed?'':'border-card'" v-if="currentUser">
        <el-tab-pane :label="$t('g.section_general')">
          <label class="form-item">{{ $t("g.email") }}</label>
          <el-row>
            <el-input
              v-model="currentUser.email"
              :disabled="true"
              type='text'>
            </el-input>
          </el-row>
          <label class="form-item">{{ $t("g.name") }}</label>
          <el-row>
            <el-input
              v-model="currentUser.name"
              @change="updateField('name', $event)"
              type='text'>
            </el-input>
          </el-row>
          <template v-if="!loading">
            <div v-for="field in user_default_profile_fields" v-bind:key="field.id">
              <label class="form-item">{{ tr(field.displayed_name) }}</label>
              <ContentField
                v-model="currentUser.profile_fields[field.field_name]"
                :fieldDef="field"
                @change="updateProfileField(field.field_name, $event)"
              />
            </div>
          </template>
        </el-tab-pane>

        <el-tab-pane :label="$t('g.section_permissions')">
          <el-alert
            v-if="currentUser.id === this.$store.state.user.user_id"
            type="warning"
            title="Edition bloquée"
            :closable="false"
            description="Vous ne pouvez pas changez vos propres permissions"/>
          <label class="form-item">{{ $t("user.staff") }}</label>
          <el-row>
            <el-checkbox
              :value="currentUser.is_staff"
              :disabled="!allowPermissions"
              @change="updateField('is_staff', $event)"/>
          </el-row>
          <label class="form-item">{{ $t("user.moderator") }}</label>
          <el-row>
            <el-checkbox
              :value="currentUser.is_moderator"
              :disabled="!allowPermissions"
              @change="updateField('is_moderator', $event)"/>
          </el-row>
          <label class="form-item">{{ $t("user.contributor") }}</label>
          <el-row>
            <el-checkbox
              :value="currentUser.is_contributor"
              :disabled="!allowPermissions"
              @change="updateField('is_contributor', $event)"/>
          </el-row>
          <label class="form-item">{{ $t("user.allow_display_current_position") }}</label>
          <el-row>
            <el-checkbox
              :value="currentUser.allow_display_current_position"
              :disabled="!allowPermissions"
              @change="updateField('allow_display_current_position', $event)"/>
          </el-row>
        </el-tab-pane>
        <!--
        <el-tab-pane :label="$t('g.section_admin')">
          <el-alert
            v-if="currentUser.id === this.$store.state.user.user_id"
            type="warning"
            title="Suppression bloquée"
            :closable="false"
            description="Vous ne pouvez pas vous supprimer vous même"/>
          <br/>
          <el-row>
            <el-col :span="8">
              <el-button
                type="danger"
                :disabled="!allowPermissions"
                @click="deleteUser()">Supprimer l'utilisateur</el-button>
            </el-col>
          </el-row>
        </el-tab-pane>
        -->

      </el-tabs>
    </el-row>

  </el-col>
  </el-row>
</template>

<script>
import Vue from 'vue'
import ContentField from './ContentField'

export default {
  name: 'AppUser',
  components: { ContentField },
  props: {
    embed: {
      type: Boolean,
      default: false
    },
    appID: {
      type: Number
    },
    userID: {
      type: Number
    }
  },

  data () {
    return {
      loading: true,
      currentUser: null,
      user_default_profile_fields: []
    }
  },

  methods: {

    async commit (field, value) {
      await this.$store.dispatch('updateUser', {
        userID: this.currentUser.id,
        [field]: value
      })
    },

    async updateField (field, value) {
      Vue.set(this.currentUser, field, value)
      this.commit(field, value)
    },

    updateProfileField (field, value) {
      let payload = this.currentUser.profile_fields
      payload[field] = value
      Vue.set(this.currentUser, 'profile_fields', payload)
      this.commit('profile_fields', payload)
    },

    deleteUser () {
      let payload = {
        appID: this.$route.params.appID,
        userID: this.$route.params.userID
      }
      this.$confirm(
        'Vous allez supprimer cet utilisateur de manière définitive. Continuez?',
        'Attention !', {
          confirmButtonText: 'Oui, je le supprime!',
          cancelButtonText: 'Annuler',
          type: 'warning'
        }).then(() => {
          this.$store.dispatch('deleteUser', payload)
          this.$router.replace({name: 'appusers', params: this.$route.params})
        })
    },

    async prepare () {
      this.loading = true
      await this.$store.dispatch('ensureApp', {
        appID: this.appID
      })
      let currentUser = await this.$store.dispatch('loadUser', {
        appID: this.appID,
        userID: this.userID
      })
      this.user_default_profile_fields = this.$store.state.app.fields.user_default_profile_fields
      for (let field of this.user_default_profile_fields) {
        if (currentUser.profile_fields[field.field_name] === undefined) {
          currentUser.profile_fields[field.field_name] = ''
        }
      }
      this.currentUser = currentUser
      this.loading = false
    }

  },

  computed: {
    currentAppState () {
      return this.$store.state.app
    },
    currentUserName () {
      if (this.currentUser) return this.currentUser.name
      return '...'
    },
    allowPermissions () {
      if (this.currentUser.id === this.$store.state.user.user_id) return false
      if (!this.$store.state.user.is_staff) return true
      return true
    }
  },

  watch: {
    '$route' (from, to) {
      this.prepare()
    }
  },

  mounted () {
    this.prepare()
  }
}
</script>

<style scoped>
</style>
