<template>
  <el-select :value="value" @change="$emit('change', $event || null)" clearable>
    <el-option
      v-for="layout in $store.state.layouts"
      :key="layout.id"
      :value="layout.id"
      :label="layout.layout_fields.name"
      />
  </el-select>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'LayoutField',
  props: {
    value: Number
  },
  data () {
    return {
      loading: true,
      localValue: {}
    }
  },
  async mounted () {
    this.localValue = cloneDeep(this.value)
    await this.$store.dispatch('ensureLayouts')
  },
  watch: {
    value () {
      this.localValue = cloneDeep(this.value)
    }
  }
}
</script>

<style scoped>
.textarea-flag {
  float: right;
  margin-top: 5px;
  margin-bottom: -2px;
  border: 1px solid #dcdfe6;
  border-bottom: none;
  background-color: #f5f7fa;
  padding: 5px 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

</style>
