<style>
</style>
<template>
    <span v-if="iso" class="flag-icon" :class="flagIconClass" :title="title || iso">
    </span>
</template>
<script>
import 'flag-icon-css/css/flag-icon.css'
export default {
  name: 'flag',
  props: {
      iso: { type: String, default: null },
      title: { type: String, default: null },
      squared: { type: Boolean, default: true }
  },
  computed: {
    flagIconClass () {
      let iso = this.iso.toLowerCase()
      if (iso === 'en') {
        iso = 'us'
      }
      return ((this.squared) ? 'flag-icon-squared ' : '') + 'flag-icon-' + iso
    }
  }
}
</script>
