<template>
  <el-row type="flex" justify="center">
    <el-col :md="20" :lg="16" :xl="10">
    <h2>{{ $t('title.appsettings') }}</h2>
    <el-row v-loading="loading">
      <el-tabs type="border-card">

        <el-tab-pane label="General">
          <label class="form-item">{{ $t("g.name") }}</label>
          <el-row>
            <el-input
              v-model="currentApp.name"
              @change="updateField('name', $event)"
              type='text'>
            </el-input>
          </el-row>

          <label class="form-item">{{ $t("g.languages") }}</label>
          <el-checkbox-group v-model="languages" @change="updateLanguages">
            <el-checkbox v-for="[lang, name] of Object.entries($config.languages)" :label="lang" v-bind:key="lang">
              {{ name }}
            </el-checkbox>
          </el-checkbox-group>
        </el-tab-pane>

        <el-tab-pane label="Champs">

          <div class="tab-section">
            <span>{{ $t("g.map_default_content_fields") }}</span>
            <el-button @click="addField('map_default_content_fields')" size="mini" icon="el-icon-plus" circle></el-button>
          </div>
          <div class="tab-section-content">
            <AppSettingsFields
              v-model="fields.map_default_content_fields"
              :fieldTypes="$config.fieldTypes"
              fieldCategory="map_default_content_fields"/>
          </div>

          <div class="tab-section">
            <span>{{ $t("g.map_default_translated_fields") }}</span>
            <el-button @click="addField('map_default_translated_fields')" size="mini" icon="el-icon-plus" circle></el-button>
          </div>
          <div class="tab-section-content">
            <AppSettingsFields
              v-model="fields.map_default_translated_fields"
              :fieldTypes="$config.fieldTranslatedTypes"
              fieldCategory="map_default_translated_fields"/>
          </div>

          <div class="tab-section">
            <span>{{ $t("g.poi_default_content_fields") }}</span>
            <el-button @click="addField('poi_default_content_fields')" size="mini" icon="el-icon-plus" circle></el-button>
          </div>
          <div class="tab-section-content">
            <AppSettingsFields
              v-model="fields.poi_default_content_fields"
              :fieldTypes="$config.fieldTypes"
              fieldCategory="poi_default_content_fields"/>
          </div>

          <div class="tab-section">
            <span>{{ $t("g.poi_default_translated_fields") }}</span>
            <el-button @click="addField('poi_default_translated_fields')" size="mini" icon="el-icon-plus" circle></el-button>
          </div>
          <div class="tab-section-content">
            <AppSettingsFields
              v-model="fields.poi_default_translated_fields"
              :fieldTypes="$config.fieldTranslatedTypes"
              fieldCategory="poi_default_translated_fields"/>
          </div>

          <div class="tab-section">
            <span>{{ $t("g.selection_default_content_fields") }}</span>
            <el-button @click="addField('selection_default_content_fields')" size="mini" icon="el-icon-plus" circle></el-button>
          </div>
          <div class="tab-section-content">
            <AppSettingsFields
              v-model="fields.selection_default_content_fields"
              :fieldTypes="$config.fieldTypes"
              fieldCategory="selection_default_content_fields"/>
          </div>

          <div class="tab-section">
            <span>{{ $t("g.selection_default_translated_fields") }}</span>
            <el-button @click="addField('selection_default_translated_fields')" size="mini" icon="el-icon-plus" circle></el-button>
          </div>
          <div class="tab-section-content">
            <AppSettingsFields
              v-model="fields.selection_default_translated_fields"
              :fieldTypes="$config.fieldTranslatedTypes"
              fieldCategory="selection_default_translated_fields"/>
          </div>

          <div class="tab-section">
            <span>{{ $t("g.category_default_content_fields") }}</span>
            <el-button @click="addField('category_default_content_fields')" size="mini" icon="el-icon-plus" circle></el-button>
          </div>
          <div class="tab-section-content">
            <AppSettingsFields
              v-model="fields.category_default_content_fields"
              :fieldTypes="$config.fieldTypes"
              fieldCategory="category_default_content_fields"/>
          </div>

          <div class="tab-section">
            <span>{{ $t("g.category_default_translated_fields") }}</span>
            <el-button @click="addField('category_default_translated_fields')" size="mini" icon="el-icon-plus" circle></el-button>
          </div>
          <div class="tab-section-content">
            <AppSettingsFields
              v-model="fields.category_default_translated_fields"
              :fieldTypes="$config.fieldTranslatedTypes"
              fieldCategory="category_default_translated_fields"/>
          </div>

          <div class="tab-section">
            <span>{{ $t("g.user_default_profile_fields") }}</span>
            <el-button @click="addField('user_default_profile_fields')" size="mini" icon="el-icon-plus" circle></el-button>
          </div>
          <div class="tab-section-content">
            <AppSettingsFields
              v-model="fields.user_default_profile_fields"
              :fieldTypes="$config.userFieldTypes"
              fieldCategory="user_default_profile_fields"/>
          </div>

        </el-tab-pane>

        <el-tab-pane v-if="this.$store.state.user.is_staff" :label="$t('g.section_debug')">
          <h2>Local app</h2>
          <pre>{{ currentApp }}</pre>
          <h2>App</h2>
          <pre>{{ currentAppState }}</pre>
        </el-tab-pane>
      </el-tabs>

    </el-row>

  </el-col>
  </el-row>

</template>

<script>
import find from 'lodash/find'
import cloneDeep from 'lodash/cloneDeep'
import Vue from 'vue'
import AppSettingsFields from './AppSettingsFields'

export default {
  name: 'AppSettings',
  components: { AppSettingsFields },
  props: ['appID'],

  data () {
    return {
      loading: true,
      checked: false,
      languages: [],
      preventFieldCommit: true,
      fields: {},
      currentApp: {}
    }
  },

  methods: {

    async commit (field, value) {
      await this.$store.dispatch('updateCurrentApp', {
        [field]: value
      })
    },

    updateFields () {
      this.updateField('fields', this.fields)
    },

    updateLanguages () {
      let payload = []
      for (let lang of this.languages) {
        let label = this.$config.languages[lang]
        payload.push({
          label: label,
          code: lang
        })
      }
      this.commit('languages', payload)
    },

    updateField (field, value) {
      Vue.set(this.currentApp, field, value)
      this.commit(field, value)
    },

    addField (section) {
      this.$prompt('Nom du champ', 'Ajouter un champ', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Annuler',
        inputPattern: /^[a-z0-9_]{2,64}$/,
        inputErrorMessage: 'Nom de champ invalide'
      }).then(value => {
        this.fields[section].push({
          field_name: value.value,
          displayed_name: {
            fr: value.value,
            en: value.value
          },
          type: 'string'
        })
        this.updateFields()
      })
    }
  },

  computed: {
    currentAppState () {
      return this.$store.state.app
    }
  },

  watch: {
    fields: {
      handler (val, oldVal) {
        if (this.preventFieldCommit) return
        this.updateFields()
      },
      deep: true
    }
  },

  async mounted () {
    await this.$store.dispatch('loadApp', {
      appID: this.appID
    })

    this.currentApp = cloneDeep(this.$store.state.app)
    let languages = []
    for (let lang of this.currentApp.languages) {
      languages.push(lang.code)
    }
    this.languages = languages

    // set default fields if they are not existing
    let fieldsNeedCommit = false
    let fields = {}
    let layoutDisplayedFields = this.currentApp.current_layout.displayed_fields

    for (const [fieldKey, field] of Object.entries(this.$config.defaultFields)) {
      // first, copy the data either from the current app field, or current layout
      // if it exists in the app, use it then
      let value = this.currentApp.fields[fieldKey]
      if (value !== undefined) {
        fields[fieldKey] = value
        continue
      }

      // search in current layout
      value = find(layoutDisplayedFields, function (v, k) {
        return k === fieldKey
      })
      if (value !== undefined) {
        fields[fieldKey] = Object.values(value)
        fieldsNeedCommit = true
        continue
      }

      // copy from defaults
      fields[fieldKey] = field
      fieldsNeedCommit = true
    }

    Vue.set(this, 'fields', fields)
    if (fieldsNeedCommit) {
      this.updateFields()
    }
    this.preventFieldCommit = false

    this.loading = false
  }
}
</script>

<style scoped>
</style>
