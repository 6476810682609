<template>
  <div>
    <el-row v-if="fieldDef.type === 'string'">
      <el-input v-model="dataValue" :placeholder="placeholder" @change="$emit('change', $event)"/>
    </el-row>
    <el-row v-else-if="fieldDef.type === 'longstring'">
      <el-input v-model="dataValue" :placeholder="placeholder" type="textarea" @change="$emit('change', $event)"/>
    </el-row>
    <el-row v-else-if="fieldDef.type === 'integer'">
      <el-input v-model="dataValue" :placeholder="placeholder" type="number" step="1" @change="emitInteger($event)"/>
    </el-row>
    <el-row v-else-if="fieldDef.type === 'float'">
      <el-input v-model="dataValue" :placeholder="placeholder" type="number" step="any" @change="emitFloat($event)"/>
    </el-row>
    <el-row v-else-if="fieldDef.type === 'boolean'">
      <el-switch v-model="dataValue" @change="$emit('change', $event)"/>
    </el-row>
    <el-row v-else-if="fieldDef.type === 'poi_id'">
      <el-select v-model="dataValue" @change="$emit('change', $event)" filterable>
        <el-option :value="null" label="-"/>
        <el-option v-for="poi in $store.state.pois" v-bind:key="poi.id" :value="poi.id" :label="buildPoiName(poi)"/>
      </el-select>
    </el-row>
    <el-row v-else-if="fieldDef.type === 'map_id'">
      <el-select v-model="dataValue" @change="$emit('change', $event)" filterable>
        <el-option :value="null" label="-"/>
        <el-option v-for="map in $store.state.maps" v-bind:key="map.id" :value="map.id" :label="buildMapName(map)"/>
      </el-select>
    </el-row>
  </div>
</template>

<script>
export default {
  name: 'ContentField',
  props: {
    value: null,
    fieldDef: Object,
    placeholder: null,
  },
  data () {
    return {
      dataValue: null
    }
  },
  mounted () {
    this.dataValue = this.value
  },
  methods: {
    emitInteger: function (value) {
      try {
        this.$emit('change', parseInt(value))
      } catch (e) {
        this.$emit('change', null)
        this.$message({
          message: 'Nombre entier invalide',
          type: 'error'
        })
      }
    },
    buildPoiName: function (poi) {
      let s = this.tf(poi, 'title', 'fr', poi.name || '(unknown)')
      s += ' (ID ' + poi.id + ')'
      return s
    },
    buildMapName: function (map) {
      let s = this.tf(map, 'title', 'fr', map.name || '(unknown)')
      s += ' (ID ' + map.id + ')'
      return s
    },
    emitFloat: function (value) {
      try {
        this.$emit('change', parseFloat(value))
      } catch (e) {
        this.$emit('change', null)
        this.$message({
          message: 'Nombre flottant invalide',
          type: 'error'
        })
      }
    }
  },
  watch: {
    value () {
      this.dataValue = this.value
    }
  }
}
</script>

<style scoped>
</style>
