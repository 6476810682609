<template>
  <div v-loading="loading" class="fullheight">
    <div id="map" class="fullheight mapeditor">
      <transition :name="showMapExpanded ? null : 'moveleft'">
        <div id="sidebar-map" v-show="showMapEdit && !addMarker" :class="{ showMapExpanded }">
          <div class="sidebar-container">
            <div class="el-buttons">
              <el-button v-if="!showMapExpanded" icon="el-icon-top-right" type="text" class="icon-map-expand" @click="showMapExpanded=true"/>
              <el-button v-if="showMapExpanded" icon="el-icon-bottom-left" type="text" class="icon-map-expand" @click="showMapExpanded=false"/>
              <el-button icon="el-icon-close" type="text" class="icon-map-close" @click="showMapEdit=false"/>
            </div>
            <router-view name="mapedit" ref="mapedit" :appID="appID" :mapID="mapID" :embed="true"/>
          </div>
        </div>
      </transition>
      <transition :name="showPoiExpanded ? null : 'moveright'">
        <div id="sidebar-poi" v-show="showPoiEdit && !addMarker && poiID" :class="{ showPoiExpanded }">
          <div class="sidebar-container">
            <div class="el-buttons">
              <el-button v-if="!showPoiExpanded" icon="el-icon-top-right" type="text" class="icon-poi-expand" @click="showPoiExpanded=true"/>
              <el-button v-if="showPoiExpanded" icon="el-icon-bottom-left" type="text" class="icon-poi-expand" @click="showPoiExpanded=false"/>
              <el-button icon="el-icon-close" type="text" class="icon-poi-close" @click="closePoiEdit()"/>
            </div>
            <router-view name="poiedit" :appID="appID" :mapID="mapID" :poiID="poiID" :embed="true" v-if="poiID"/>
          </div>
        </div>
      </transition>
      <el-button
        icon="el-icon-caret-right"
        type="text"
        class="icon-map-edit"
        v-show="!showMapEdit"
        @click="showMapEdit=true"/>
      <el-button
        icon="el-icon-caret-left"
        type="text"
        class="icon-poi-edit"
        v-show="!showPoiEdit && currentPoi !==  null"
        @click="showPoiEdit=true"/>
      <el-alert
        v-if="!loading && !sourceUrl"
        title="Veuillez renseigner une source de carte"
        type="error"
        center
        :closable="false"/>
      <div
        id="map-controls"
        v-if="!loading && sourceUrl">
        <el-button-group v-if="!addMarker && !addGeojson">
        <el-button icon="el-icon-zoom-out" :disabled="ctrlMinZoomDisabled" @click="zoomOut()"/>
        <el-button icon="el-icon-zoom-in" :disabled="ctrlMaxZoomDisabled" @click="zoomIn()"/>
        <el-button @click="addMarker=true">
          <font-awesome-icon icon="map-marker" />
        </el-button>
        <el-button @click="addGeojsonStart()">
          <font-awesome-icon icon="draw-polygon" />
        </el-button>
        </el-button-group>
        <el-button v-if="addMarker || addGeojson" type="danger" @click="cancelAdd()">Annuler</el-button>
        <el-input prefix-icon="el-icon-search" v-model="poiFilterText" style="margin-left: 10px; width: auto;" clearable>
            <template slot="append">{{poisFiltered.length}} / {{pois.length}}</template>
        </el-input>
      </div>
      <l-map
        v-if="!loading && sourceUrl"
        ref="map"
        class="fullheight"
        :crs="crs"
        :bounds="bounds"
        :minZoom="minZoom"
        :maxZoom="maxZoom"
        :zoom="zoom"
        :center="center"
        @zoomend="updateCurrentZoom($event)"
        @mousemove="handleMapMouseMove($event)"
        @click="handleMapClick($event)"
        >
        <l-tile-layer
          :url="sourceUrl"
          :options="{maxZoom: maxZoom, minZoom: minZoom}"
          v-if="isTileLayer"/>
        <l-image-overlay
          v-if="!isTileLayer"
          :bounds="imageBounds"
          :url="sourceUrl"/>
        <l-layer-group>
          <l-geo-json
            v-for="geojson of displayableGeojsons"
            v-bind:key="geojson.id"
            :geojson="geojson.geojson"
            :optionsStyle="geojson.style"
            @click="editPoi(geojson)"/>
          <l-marker
            v-for="poi of poisFiltered"
            v-bind:key="poi.id"
            :lat-lng="poi.latlng"
            :icon="poi.icon"
            :draggable="poi.draggable"
            @dragend="dragEndPoi(poi, $event)"
            @click="editPoi(poi)"/>
          <l-marker
            v-if="addMarker"
            :lat-lng="addMarkerLatLng"/>
        </l-layer-group>

        <!-- boundary -->
        <l-layer-group name="bounds"
          v-if="currentMap && currentMap.bounds && bounds">
          <l-marker
            :lat-lng="getLatLng(editableGeojsonBounds.geometry.coordinates[0])"
            :icon="editableGeojsonDivicon"
            :draggable="true"
            @dragend="editGeojsonBoundsCoordinate(0, $event)"
          />
          <l-marker
            :lat-lng="getLatLng(editableGeojsonBounds.geometry.coordinates[2])"
            :icon="editableGeojsonDivicon"
            :draggable="true"
            @dragend="editGeojsonBoundsCoordinate(1, $event)"
          />
          <l-geo-json
            :geojson="editableGeojsonBounds"
            :optionsStyle="editableGeojsonBoundsStyle"/>
        </l-layer-group>


        <!-- editable geojson -->
        <l-layer-group name="edit-polygon">
          <l-geo-json
            v-if="addGeojson || editGeojson"
            :options-style="geojsonCurrentPolygonStyle"
            :geojson="editableGeojsonPolygon"/>
        </l-layer-group>
        <l-layer-group name="edit-outerline">
          <l-geo-json
            v-for="geojson in editableGeojsonOuterline"
            v-bind:key="JSON.stringify(geojson)"
            :options-style="geojsonCurrentOuterlineStyle"
            :geojson="geojson"/>
        </l-layer-group>
        <l-layer-group name="edit-outerline-selectable">
          <l-geo-json
            v-for="(geojson, index) in editableGeojsonOuterline"
            v-bind:key="JSON.stringify(geojson)"
            :options-style="geojsonCurrentOuterlineStyleBig"
            :geojson="geojson"
            v-on:dblclick="createGeojsonMarker(index, $event)"/>
        </l-layer-group>
        <l-layer-group name="edit-currentline">
          <l-geo-json
            v-if="addGeojson"
            :geojson="editableGeojsonCurrentline"/>
        </l-layer-group>
        <l-layer-group name="edit-markers">
          <l-marker
            v-bind:key="index"
            v-for="(latlng, index) of editableGeojson"
            :lat-lng="getLatLng(latlng)"
            :icon="editableGeojsonDivicon"
            :draggable="true"
            @dragend="editGeojsonCoordinate(index, $event)"
            @click="clickGeojsonMarker(index, $event)"
            v-on:dblclick="removeGeojsonMarker(index)"/>
          <l-marker
            v-if="addGeojson"
            :lat-lng="editGeojsonLatLng"
            :icon="editableGeojsonDivicon" />
        </l-layer-group>
      </l-map>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
import min from 'lodash/min'
import max from 'lodash/max'
import extend from 'lodash/extend'
import L from 'leaflet'
// import Vue from 'vue'
import { asset, defaultValue, ensureNumber } from '../utils'
import {
  LMap, LTileLayer, LImageOverlay, LMarker, LLayerGroup,
  LGeoJson, LIcon
} from 'vue2-leaflet'

export default {
  name: 'AppMapFull',
  props: ['appID', 'mapID'],
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LLayerGroup,
    LImageOverlay,
    LGeoJson
  },

  data () {
    return {
      loading: true,
      poiID: null,
      showPoiEdit: false,
      showMapEdit: true,
      showPoiExpanded: false,
      showMapExpanded: false,
      addMarker: false,
      addMarkerLatLng: [0, 0],
      addGeojson: false,
      editGeojson: false,
      editableGeojson: [],
      editGeojsonLatLng: [0, 0],
      minZoom: undefined,
      maxZoom: undefined,
      zoom: undefined,
      isTileLayer: false,
      isIndoor: false,
      sourceUrl: true,
      crs: null,
      bounds: null,
      imageBounds: null,
      pois: [],
      geojsons: [],
      center: undefined,
      currentPoi: null,
      currentMap: null,
      currentZoom: 0,
      poiFilterText: '',
      editableGeojsonDivicon: L.divIcon({
        iconSize: new L.Point(8, 8),
        className: 'll-divicon-geojson'
      }),
      geojsonCurrentPolygonStyle: {
        opacity: 0
      },
      geojsonCurrentOuterlineStyle: {
      },
      geojsonCurrentOuterlineStyleBig: {
        opacity: 0,
        weight: 20
      },
      geojsonCurrentLineStyle: {
        color: '#ed1919'
      }
    }
  },

  methods: {
    // map handlers
    handleMapMouseMove (ev) {
      if (this.addMarker) return this.updateAddMarker(ev)
      if (this.addGeojson || this.editGeojson) return this.updateAddGeojson(ev)
    },

    handleMapClick (ev) {
      if (this.addMarker) return this.clickAddMarker(ev)
      if (this.addGeojson) return this.clickAddGeojson(ev)
    },

    // geojson editor
    addGeojsonStart () {
      this.poiID = null
      this.editableGeojson = []
      this.geojsonCurrentPolygonStyle = {opacity: 0}
      this.geojsonCurrentOuterlineStyle = {}
      this.showPoiEdit = false
      this.showMapEdit = false
      this.addMarker = false
      this.currentPoi = null
      this.editGeojson = false
      this.addGeojson = true
    },

    clickAddGeojson (ev) {
      this.editableGeojson.push([ev.latlng.lng, ev.latlng.lat])
    },

    updateAddGeojson (ev) {
      this.editGeojsonLatLng = [ev.latlng.lng, ev.latlng.lat]
    },

    editGeojsonCoordinate (index, ev) {
      let ll = ev.target._latlng
      let geojson = cloneDeep(this.editableGeojson)
      geojson[index] = [ll.lng, ll.lat]
      if (this.editGeojson) {
        if (index === 0) {
          geojson[geojson.length - 1] = geojson[0]
        } if (index === geojson.length - 1) {
          geojson[0] = geojson[geojson.length - 1]
        }
      }
      this.editableGeojson = geojson
      this.commitEditableGeojson()
    },

    commitEditableGeojson () {
      if (this.editGeojson) {
        let payload = {
          appID: this.currentPoi.data.application,
          mapID: this.currentPoi.data.map,
          poiID: this.currentPoi.data.id
        }
        let position = {
          type: 'Polygon',
          coordinates: [this.editableGeojson]
        }
        if (this.isIndoor) {
          payload.indoor_position = position
        } else {
          payload.outdoor_position = position
        }
        this.$store.dispatch('updatePoi', payload)
        this.currentPoi.data.outdoor_position = position
        this.refreshPoi(this.currentPoi.data)
      }
    },

    removeGeojsonMarker (index) {
      if (this.editableGeojson.length <= 4) {
        this.$message({
          message: `Vous ne pouvez pas supprimer les 3 derniers points du polygone`,
          type: 'warning'
        })
        return
      }
      let geojson = cloneDeep(this.editableGeojson)
      if (this.editGeojson) {
        // special case if we remove 0 index, since it's duplicated
        // we need to replace it
        if (index === 0 || index === this.editableGeojson.length - 1) {
          geojson = geojson.splice(1, geojson.length - 2)
          if (geojson.length > 0) {
            geojson.push(geojson[0])
          }
        } else {
          geojson.splice(index, 1)
        }
        this.editableGeojson = geojson
        this.commitEditableGeojson()
      } else {
        geojson.splice(index, 1)
        this.editableGeojson = geojson
      }
    },

    createGeojsonMarker (index, ev) {
      this.$refs.map.mapObject.doubleClickZoom.disable()
      setTimeout(() => {
        this.$refs.map.mapObject.doubleClickZoom.enable()
      }, 100)
      let geojson = cloneDeep(this.editableGeojson)
      if (index === geojson.length - 1) {
        index = 0
      }
      geojson.splice(index + 1, 0, [ev.latlng.lng, ev.latlng.lat])
      this.editableGeojson = geojson
      if (this.editableGeojson) {
        this.commitEditableGeojson()
      }
      return false
    },

    clickGeojsonMarker (index, /* ev */) {
      if (index !== 0) return
      this.editableGeojson.push(this.editableGeojson[0])
      let position = {
        type: 'Polygon',
        coordinates: [this.editableGeojson]
      }
      let payload = {
        appID: this.$route.params.appID,
        mapID: this.$route.params.mapID,
        payload: {
          translated_fields: {
            title: {
              'fr': 'Nom temporaire',
              'en': 'Temporary name'
            }
          }
        }
      }
      if (this.isIndoor) {
        payload.payload.indoor_position = position
      } else {
        payload.payload.outdoor_position = position
      }
      this.addGeojson = false
      this.$store
        .dispatch('createPoi', payload)
        .then(response => {
          this.showPoiEdit = true
          this.$router.push({
            name: 'applivepoi',
            params: extend(this.$route.params, {poiID: response.id})
          })
        })
    },

    editGeojsonBoundsCoordinate (index, ev) {
      let ll = ev.target._latlng

      const bounds = cloneDeep(this.currentMap.bounds);
      if (index === 0) {
        bounds.min_lon = ll.lng;
        bounds.min_lat = ll.lat;
      } else if (index === 1) {
        bounds.max_lon = ll.lng;
        bounds.max_lat = ll.lat;
      }
      this.$store.dispatch('updateCurrentMap', { bounds }).then(() => {
        this.$refs.mapedit.reload();
      })

    },

    getLatLng (latlng) {
      return L.latLng(latlng[1], latlng[0])
    },

    // general
    closePoiEdit () {
      this.showPoiEdit = false
      this.editableGeojson = []
      this.currentPoi = null
    },
    cancelAdd () {
      if (this.addMarker) {
        this.addMarker = false
      }
      if (this.addGeojson) {
        this.addGeojson = false
        this.editableGeojson = []
      }
    },
    initiateCreatePoi () {
    },
    editPoi (poi) {
      if (this.currentPoi !== null) {
        this.currentPoi.draggable = false
      }
      this.currentPoi = poi
      this.currentPoi.draggable = true
      this.showPoiEdit = true
      this.editGeojson = false
      this.addGeojson = false
      this.addMarker = false

      if (this.pois.includes(poi)) {
        this.editableGeojson = []
        this.editGeojson = false
      } else if (this.geojsons.includes(poi)) {
        this.updateEditStyle(poi)
        this.editableGeojson = poi.geojson.coordinates[0]
        this.editGeojson = true
      }

      this.poiID = poi.id
      this.$router.push({
        name: 'applivepoi',
        params: extend(this.$route.params, {poiID: poi.id})
      })
    },
    updateEditStyle (poi) {
      this.geojsonCurrentPolygonStyle = {
        color: '#000000',
        opacity: 0,
        weight: 0,
        fillColor: defaultValue(poi.data.properties['fill'], '#3388ff'),
        fillOpacity: defaultValue(ensureNumber(poi.data.properties['fill-opacity']), 0.2)
      }
      this.geojsonCurrentOuterlineStyle = {
        color: defaultValue(poi.data.properties['stroke'], '#3388ff'),
        opacity: defaultValue(ensureNumber(poi.data.properties['opacity']), 1),
        weight: defaultValue(ensureNumber(poi.data.properties['stroke-width']), 3)
      }
    },
    dragEndPoi (poi, ev) {
      let payload = {
        appID: poi.data.application,
        mapID: poi.data.map,
        poiID: poi.data.id
      }
      let position = {
        type: 'Point',
        coordinates: [ev.target._latlng.lng, ev.target._latlng.lat]
      }
      if (this.isIndoor) {
        payload.indoor_position = position
      } else {
        payload.outdoor_position = position
      }
      this.$store.dispatch('updatePoi', payload)
    },
    updateCurrentZoom (ev) {
      this.currentZoom = ev.target._zoom
    },
    zoomIn () {
      this.zoom = min([this.maxZoom, this.zoom + 1])
    },
    zoomOut () {
      this.zoom = max([this.minZoom, this.zoom - 1])
    },
    updateAddMarker (ev) {
      if (!this.addMarker) return
      this.addMarkerLatLng = ev.latlng
    },
    clickAddMarker (ev) {
      if (!this.addMarker) return
      let position = {
        type: 'Point',
        coordinates: [ev.latlng.lng, ev.latlng.lat]
      }
      let payload = {
        appID: this.$route.params.appID,
        mapID: this.$route.params.mapID,
        payload: {
          translated_fields: {
            title: {
              'fr': 'Nom temporaire',
              'en': 'Temporary name'
            }
          }
        }
      }
      if (this.isIndoor) {
        payload.payload.indoor_position = position
      } else {
        payload.payload.outdoor_position = position
      }
      this.addMarker = false
      this.$store
        .dispatch('createPoi', payload)
        .then(response => {
          this.showPoiEdit = true
          this.$router.push({
            name: 'applivepoi',
            params: extend(this.$route.params, {poiID: response.id})
          })
        })
    },
    loadPoi (poi) {
      let position = null
      if (this.isIndoor) {
        position = poi.indoor_position
      } else {
        position = poi.outdoor_position
      }
      if (!position) return
      if (position.type === 'Point') {
        this.pois = this.pois.filter(entry => poi.id !== entry.id)
        let newpoi = this.pushPoi(poi, position)
        if (this.currentPoi !== null && this.currentPoi.id === poi.id) {
          this.currentPoi = newpoi
          this.currentPoi.draggable = true
        }
      } else if (position.type === 'Polygon') {
        this.geojsons = this.geojsons.filter(entry => poi.id !== entry.id)
        let newpoi = this.pushGeojson(poi, position)
        if (this.currentPoi !== null && this.currentPoi.id === poi.id) {
          this.currentPoi = newpoi
          this.currentPoi.draggable = true
          this.updateEditStyle(newpoi)
        }
      }
      return null
    },
    pushGeojson (poi, position) {
      let style = {}
      if (poi.properties !== undefined) {
        if (poi.properties['stroke'] !== undefined) {
          style['color'] = poi.properties['stroke']
        }
        if (poi.properties['stroke-width'] !== undefined) {
          style['weight'] = poi.properties['stroke-width']
        }
        if (poi.properties['fill'] !== undefined) {
          style['fillColor'] = poi.properties['fill']
        }
        if (poi.properties['fill-opacity'] !== undefined) {
          style['fillOpacity'] = poi.properties['fill-opacity']
        }
        if (poi.properties['opacity'] !== undefined) {
          style['opacity'] = poi.properties['opacity']
        }
      }
      let newPoi = {
        id: poi.id,
        data: poi,
        geojson: position,
        style: style
      }
      this.geojsons.push(newPoi)
      return newPoi
    },
    pushPoi (poi, position) {
      let latlng = position.coordinates
      let icon = undefined
      if (poi.icon_url) {
        let md = poi.icon_metadata
        let useCustomSize = md.use_custom
        let iwidth, iheight
        let assetUrl

        // determinate the ratio
        let oWidth = md.original_width || 48
        let oHeight = md.original_height || 48
        let ratio = oWidth / oHeight

        // determinate the icon width/height
        if (useCustomSize === undefined) {
          useCustomSize = true
        }

        if (useCustomSize) {
          iwidth = md.width || oWidth
          iheight = md.height || oHeight
          assetUrl = asset(poi.icon_url, iwidth, iheight)
        } else {
          let boxSize = md.box_size
          if (boxSize === undefined) {
            boxSize = 48
          }
          iwidth = boxSize
          iheight = boxSize
          if (iwidth > iheight) {
            iwidth = iheight * ratio
          } else {
            iheight = iwidth / ratio
          }
          assetUrl = asset(poi.icon_url, boxSize)
        }

        // determinate the anchor
        let anchor = md.anchor || 'center'
        let ax, ay
        if (anchor === 'center') {
          ax = iwidth / 2
          ay = iheight / 2
        } else if (anchor === 'top') {
          ax = iwidth / 2
          ay = 0
        } else if (anchor === 'top-left') {
          ax = 0
          ay = 0
        } else if (anchor === 'top-right') {
          ax = iwidth
          ay = 0
        } else if (anchor === 'left') {
          ax = 0
          ay = iheight / 2
        } else if (anchor === 'right') {
          ax = iwidth
          ay = iheight / 2
        } else if (anchor === 'bottom-right') {
          ax = 0
          ay = iheight
        } else if (anchor === 'bottom-right') {
          ax = iwidth
          ay = iheight / 2
        } else {
          ax = iwidth / 2
          ay = iheight
        }

        icon = L.divIcon({
          html: '<img src="' + assetUrl + '" class="divicon"/>',
          iconSize: [iwidth, iheight],
          iconAnchor: [ax, ay]
        })
      }
      let newPoi = {
        id: poi.id,
        draggable: false,
        data: poi,
        latlng: L.latLng(latlng[1], latlng[0]),
        icon: icon
      }
      this.pois.push(newPoi)
      return newPoi
    },
    refreshPoi (poi) {
      let guiPoi = this.pois.filter(iter => iter.id === poi.id)
      if (guiPoi.length === 1) {
        let index = this.pois.indexOf(guiPoi[0])
        if (index >= 0) {
          this.pois.splice(index, 1)
        }
      }
      if (poi.map === this.$store.state.currentMap.id) {
        this.loadPoi(poi)
      } else {
        this.showPoiEdit = false
        this.currentPoi = null
      }
    },
    loadPois () {
      this.pois = []
      let foundEditPoi = false
      for (let poi of this.$store.state.pois) {
        this.loadPoi(poi)
        if (this.currentPoi !== null && poi.id === this.currentPoi.id) {
          foundEditPoi = true
        }
      }
      if (!foundEditPoi && this.currentPoi !== null) {
        // entry got deleted
        this.pois = this.pois.filter(entry => {
          if (this.currentPoi === null) return true
          return entry.id !== this.currentPoi.id
        })
        this.geojsons = this.geojsons.filter(entry => {
          if (this.currentPoi === null) return true
          return entry.id !== this.currentPoi.id
        })
        this.currentPoi = null
        this.editableGeojson = []
      }
    },
    async prepareLoading () {
      // having a async loading allow leaflet widget to be reconstructed
      // from scratch, in case CRS changes, as it's not supported in
      // leaflet in live
      this.loading = true
    },

    async prepare () {
      await this.prepareLoading()
      this.currentMap = cloneDeep(this.$store.state.currentMap)
      this.sourceUrl = this.getSourceUrl(this.currentMap.source_url)

      // check if it's a tile or image
      if (this.currentMap.source_url.indexOf('{z}') !== -1) {
        this.isTileLayer = true
      }

      if (this.currentMap.is_indoor) {
        this.isIndoor = true
        this.crs = L.CRS.Simple
      } else {
        this.isIndoor = false
        this.crs = L.CRS.EPSG3857
      }

      if (this.currentMap.bounds !== null && Object.entries(this.currentMap.bounds).length) {
        let cbounds = this.currentMap.bounds

        // fix for inverted bounds in apps 2
        if (cbounds.min_lon > 10 && this.currentAppState.id === 2) {
          let swp = cbounds.min_lat
          cbounds.min_lat = cbounds.min_lon
          cbounds.min_lon = swp
          swp = cbounds.max_lat
          cbounds.max_lat = cbounds.max_lon
          cbounds.max_lon = swp
        }

        if (this.isIndoor) {
          cbounds.min_lat = (cbounds.min_lat === undefined) ? 0 : cbounds.min_lat
          cbounds.min_lon = (cbounds.min_lon === undefined) ? 0 : cbounds.min_lon
          cbounds.max_lat = (cbounds.max_lat === undefined) ? 100 : cbounds.max_lat
          cbounds.max_lon = (cbounds.max_lon === undefined) ? 100 : cbounds.max_lon
        } else {
          cbounds.min_lat = (cbounds.min_lat === undefined) ? -85 : cbounds.min_lat
          cbounds.min_lon = (cbounds.min_lon === undefined) ? -180 : cbounds.min_lon
          cbounds.max_lat = (cbounds.max_lat === undefined) ? 85 : cbounds.max_lat
          cbounds.max_lon = (cbounds.max_lon === undefined) ? 180 : cbounds.max_lon
        }
        this.bounds = this.imageBounds = [
          {lat: cbounds.min_lat, lon: cbounds.min_lon},
          {lat: cbounds.max_lat, lon: cbounds.max_lon}
        ]
      } else if (this.isIndoor) {
        this.bounds = null;
        this.imageBounds = [
          {lat: 0, lon: 0},
          {lat: 100, lon: 100}
        ]
      } else {
        this.bounds = null;
        this.imageBounds = null;
      }

      if (this.currentMap.default_zoom !== null) {
        this.zoom = this.currentZoom = this.currentMap.default_zoom
      } else {
        this.zoom = 1
      }
      if (this.currentMap.min_zoom !== null) {
        this.minZoom = this.currentMap.min_zoom
      }
      if (this.currentMap.max_zoom !== null) {
        this.maxZoom = this.currentMap.max_zoom
      }
      this.loadPois()

      this.map_default_content_fields = this.$store.state.app.fields.map_default_content_fields
      this.map_default_translated_fields = this.$store.state.app.fields.map_default_translated_fields
      this.loading = false

      this.$nextTick(() => {
        if (this.currentMap.center_lat === null) {
          this.center = [0, 0]
        } else {
          // eslint-disable-next-line
          this.center = new L.latLng(this.currentMap.center_lat, this.currentMap.center_lon)
        }
      })
    },

    doKeyboardCommand (e) {
      if (e.keyCode === 27 && e.target.tagName === 'INPUT') {
        e.target.blur()
        return
      }
      if (
          e.target !== document.body &&
          e.target.className.indexOf('leaflet') === -1) {
        return
      }
      if (e.keyCode === 27) {
        if (this.showPoiEdit && this.showPoiExpanded) {
          this.showPoiEdit = false
        }
        if (this.showMapEdit && this.showMapExpanded) {
          this.showMapEdit = false
        }
      } else if (e.keyCode === 69) {
        if (this.showPoiEdit) {
          this.showMapEdit = false
          this.showPoiExpanded = !this.showPoiExpanded
        } else if (this.showMapEdit) {
          this.showMapExpanded = !this.showMapExpanded
        }
      }
    }
  },

  computed: {
    ctrlMaxZoomDisabled () {
      if (this.maxZoom !== undefined) return this.currentZoom >= this.maxZoom
      return false
    },
    ctrlMinZoomDisabled () {
      if (this.minZoom !== undefined) return this.currentZoom <= this.minZoom
      return false
    },
    languages () {
      return this.$store.state.languages
    },
    currentAppState () {
      return this.$store.state.app
    },
    currentMapState () {
      return this.$store.state.currentMap
    },
    mapTitle () {
      return this.$store.getters.getMapTitle
    },
    displayableGeojsons () {
      return this.geojsons.filter(geojson => {
        if (this.currentPoi === null) return true
        return geojson.id !== this.currentPoi.id
      })
    },
    editableGeojsonBounds() {
      if (this.bounds !== null) {
        return {
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: [
              [this.bounds[0].lon, this.bounds[0].lat],
              [this.bounds[1].lon, this.bounds[0].lat],
              [this.bounds[1].lon, this.bounds[1].lat],
              [this.bounds[0].lon, this.bounds[1].lat],
              [this.bounds[0].lon, this.bounds[0].lat],
            ]
          }
        };
      } else {
        return null;
      }
    },
    editableGeojsonBoundsStyle() {
      return {
        color: '#000000',
        fillColor: '#ffffff00',
        opacity: .8,
        weight: 2,
        dashArray: [5, 10]
      }
    },
    editableGeojsonPolygon () {
      let geojson = cloneDeep(this.editableGeojson)
      if (this.addGeojson) geojson.push(this.editGeojsonLatLng)
      return {
        'type': 'Feature',
        'geometry': {
          'type': 'Polygon',
          'coordinates': [geojson]
        }
      }
    },
    editableGeojsonOuterline () {
      let geojson = cloneDeep(this.editableGeojson)
      if (this.addGeojson) geojson.push(this.editGeojsonLatLng)
      let result = []
      for (let i = 0; i < geojson.length - 1; i++) {
        result.push({
          'type': 'Feature',
          'geometry': {
            'type': 'LineString',
            'coordinates': [geojson[i], geojson[i + 1]]
          }
        })
      }
      return result
    },
    editableGeojsonCurrentline () {
      let geojson = []
      if (this.editableGeojson.length !== 0) {
        geojson = [
          this.editableGeojson[this.editableGeojson.length - 1],
          this.editGeojsonLatLng]
      }
      return {
        'type': 'Feature',
        'geometry': {
          'type': 'LineString',
          'coordinates': geojson
        }
      }
    },
    poisFiltered () {
      if (!this.poiFilterText.length) {
        return this.pois
      }
      console.log(this.poiFilterText)
      const pattern = this.poiFilterText.toLowerCase();
      const fields = ['title', 'subtitle'];
      return this.pois.filter(poi => {
        if ('' + poi.id === pattern) return true;
        for (const field of fields) {
          if (!poi.data.translated_fields[field]) continue;
          for (const lang in poi.data.translated_fields[field]) {
            if (poi.data.translated_fields[field][lang].toLowerCase().indexOf(pattern) !== -1) {
              return true;
            }
          }
        }
      });
    },
  },

  watch: {
    '$store.state.pois' () {
      if (this.loading) return
      this.loadPois()
    },
    '$store.state.currentPoi' () {
      if (this.$store.state.currentPoi !== null) {
        if (this.$store.state.currentPoi.id !== undefined) {
          this.refreshPoi(this.$store.state.currentPoi)
        }
      }
    },
    '$store.state.currentMap': {
        handler (val, oldVal) {
          if (this.loading) return
          let needReload = false
          needReload |= oldVal.source_url !== val.source_url
          needReload |= oldVal.is_indoor !== val.is_indoor
          needReload |= oldVal.center_lat !== val.center_lat
          needReload |= oldVal.center_lon !== val.center_lon
          needReload |= oldVal.bounds.min_lat !== val.bounds.min_lat
          needReload |= oldVal.bounds.min_lon !== val.bounds.min_lon
          needReload |= oldVal.bounds.max_lat !== val.bounds.max_lat
          needReload |= oldVal.bounds.max_lon !== val.bounds.max_lon
          needReload |= oldVal.min_zoom !== val.min_zoom
          needReload |= oldVal.max_zoom !== val.max_zoom
          needReload |= oldVal.default_zoom !== val.default_zoom
          if (needReload) {
            this.prepare()
          }
        },
        deep: true
    }
  },

  async created () {
    await this.$store.dispatch('ensureApp', {
      appID: this.appID
    })
    await this.$store.dispatch('ensureMap', {
      appID: this.appID,
      mapID: this.mapID
    })
    await this.$store.dispatch('loadPois', {
      appID: this.appID,
      mapID: this.mapID
    })
    await this.prepare()
  },

  mounted () {
    window.addEventListener('keydown', this.doKeyboardCommand)
  },

  beforeDestroy () {
    window.removeEventListener('keydown', this.doKeyboardCommand)
  }
}
</script>

<style scoped>
.image {
  width: 100%;
  display: block;
}

#map {
  overflow-x: hidden;
  margin: -20px;
}

#sidebar-map {
  background: white;
  height: 100%;
  position: absolute;
  z-index: 2000;
  width: 400px;
  overflow-y: auto;
  left: 0;
  box-shadow: rgba(0, 0, 0, 0.4) 10px 0px 20px -10px;
  padding: 15px 20px;
}
#sidebar-poi {
  background: white;
  height: 100%;
  position: absolute;
  z-index: 2000;
  right: 0;
  width: 400px;
  overflow-y: auto;
  box-shadow: rgba(0, 0, 0, 0.4) -10px 0px 20px -10px;
  padding: 15px 20px;
}

#sidebar-poi.showPoiExpanded,
#sidebar-map.showMapExpanded {
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: initial;
  background: transparent;
  backdrop-filter: blur(40px);
}

#sidebar-map.showMapExpanded {
  z-index: 3000;
}

#sidebar-poi.showPoiExpanded .sidebar-container,
#sidebar-map.showMapExpanded .sidebar-container {
  max-width: 800px;
  margin: auto;
  background: white;
  padding: 30px;
}

.el-buttons {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 2005;
}
.el-buttons * {
  font-size: 24px;
  padding: 0;
}

#sidebar-poi.showPoiExpanded .el-buttons,
#sidebar-map.showMapExpanded .el-buttons {
  top: 10px;
  right: 10px;
}

.icon-map-edit {
  position: fixed;
  left: -35px;
  padding: 0;
  margin: 0;
  font-size: 90px;
  z-index: 2005;
  top: 40%;
  color: #666666;
}

.icon-poi-edit {
  position: fixed;
  right: -32px;
  padding: 0;
  margin: 0;
  font-size: 90px;
  z-index: 2005;
  top: 40%;
  color: #666666;
}

.moveleft-enter-active, .moveleft-leave-active {
  transition: all .3s ease;
}
.moveleft-enter, .moveleft-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translateX(-420px);
}
.moveright-enter-active, .moveright-leave-active {
  transition: all .3s ease;
}
.moveright-enter, .moveright-leave-to /* .fade-leave-active below version 2.1.8 */ {
  transform: translateX(420px);
}

#map-controls {
  margin: auto;
  display: flex;
  justify-content: center;
  width: 100%;
  z-index: 1500;
  text-align: center;
  position: absolute;
  margin-top: 10px;
}

</style>
<style>
.mapeditor .leaflet-marker-icon.leaflet-marker-draggable {
    --stroke-pos: 1.35px;
    --stroke-neg: -1px;
    --stroke-color: #ef5350dd;
    filter: drop-shadow(var(--stroke-pos) 0 0 var(--stroke-color))
      drop-shadow(var(--stroke-neg) 0 var(--stroke-color))
      drop-shadow(0 var(--stroke-pos) 0 var(--stroke-color))
      drop-shadow(0 var(--stroke-neg) 0 var(--stroke-color))
      drop-shadow(var(--stroke-pos) var(--stroke-pos) 0 var(--stroke-color))
      drop-shadow(var(--stroke-pos) var(--stroke-neg) 0 var(--stroke-color))
      drop-shadow(var(--stroke-neg) var(--stroke-pos) 0 var(--stroke-color))
      drop-shadow(var(--stroke-neg) var(--stroke-neg) 0 var(--stroke-color));
}

.leaflet-div-icon {
  background: transparent !important;
  border: none !important;
}
.leaflet-marker-draggable {
  cursor: move !important;
}
.leaflet-control-container {
  display: none;
}

.ll-divicon-geojson {
    border-radius: 3px;
    background: #fff;
    border: 1px solid #999;
    border-color: rgba(0,0,0,.4);
}

.showMapExpanded #content-wrapper.embed h2 {
  display: block !important;
}

</style>
