<template>
  <el-row v-if="localValue" :gutter="gutter">
    <el-col :span="24">
      <label class="form-item">Platforme d'affichage</label>
      <el-checkbox-group v-model="localValue" @change="$emit('change', localValue)" size="small">
        <el-checkbox-button label="web">Site web</el-checkbox-button>
        <el-checkbox-button label="insitu">In Situ</el-checkbox-button>
        <el-checkbox-button label="mobile">Mobile</el-checkbox-button>
        <el-checkbox-button label="inline">Inline</el-checkbox-button>
      </el-checkbox-group>
    </el-col>
  </el-row>
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'PlatformField',
  props: {
    value: Array,
    gutter: {
      type: Number,
      default: 0
    },
    span: {
      type: Number,
      default: 24
    }
  },
  data () {
    return {
      localValue: ['web', 'insitu', 'mobile', 'inline']
    }
  },
  computed: {
  },
  mounted () {
    if (this.value === undefined || this.value.length === 0) {
      this.localValue = ['web', 'insitu', 'mobile', 'inline']
    } else {
      this.localValue = cloneDeep(this.value)
    }
  },
  watch: {
    value () {
      if (this.value === undefined || this.value.length === 0) {
        this.localValue = ['web', 'insitu', 'mobile', 'inline']
      } else {
        this.localValue = cloneDeep(this.value)
      }
    }
  }
}
</script>

<style scoped>
.textarea-flag {
  float: right;
  margin-top: 5px;
  margin-bottom: -2px;
  border: 1px solid #dcdfe6;
  border-bottom: none;
  background-color: #f5f7fa;
  padding: 5px 10px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

</style>
